import React from "react";
import { Box, Button, Input, Typography, CircularProgress } from "@mui/joy";
import Zekay from "../../styles/images/zekay_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { LoginForm } from "../../api/auth/Auth.typed";
import { useAlert } from "../../hooks/useAlert";

export default function ResetPasswordPage() {
  const [password1, setPassword1] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { verifyToken, resetPassword } = React.useContext(AuthContext);
  const location = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const handleReset = () => {
    const token = new URLSearchParams(location.search).get("token");
    const email = new URLSearchParams(location.search).get("email");

    if (password1.length >= 8) {
      if (token && email) {
        if (password1 === password2) {
          const h: LoginForm = {
            Email: email,
            Token: token,
            Password: password2,
          };

          setIsLoading(true);
          resetPassword(h)
            .then(() => {
              showAlert("Mot de passe réinitialisé avec succès !", "success");
              navigate("/");
            })
            .catch(() =>
              showAlert(
                "Une erreur est survenue. Veuillez réessayer.",
                "danger"
              )
            )
            .finally(() => setIsLoading(false));
        } else {
          showAlert("Les mots de passe ne sont pas identiques", "danger");
        }
      } else {
        showAlert("Le lien est invalide ou a expiré.", "danger");
      }
    } else {
      showAlert(
        "Le mot de passe doit contenir au moins 8 caractères.",
        "danger"
      );
    }
  };

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");

    if (token) {
      const h: LoginForm = {
        Token: token,
      };

      verifyToken(h).then((r) => setIsValid(!!r));
    } else {
      setIsValid(false);
    }
  }, [location.search]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "background.body",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img src={Zekay} alt="Logo" width="200px" />
        </Box>

        {isValid ? (
          <>
            <Typography level="h4" sx={{ marginBottom: 2 }}>
              Réinitialisez votre mot de passe
            </Typography>
            <Input
              placeholder="Nouveau mot de passe"
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              sx={{ marginY: 1 }}
            />
            <Input
              placeholder="Confirmez le mot de passe"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              sx={{ marginY: 1 }}
            />
            <Button
              variant="solid"
              color="primary"
              sx={{ marginTop: 2, width: "100%" }}
              onClick={handleReset}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size="sm" /> : "Réinitialiser"}
            </Button>
          </>
        ) : (
          <>
            <Typography level="h4" sx={{ marginBottom: 2 }}>
              Désolé :(
            </Typography>
            <Typography level="h4">
              Cette page a expiré ou n'est plus valide.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}
