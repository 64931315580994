import React, { ReactNode } from "react";
import { AuthAdapter } from "../api/auth/AuthAdapter";
import { AppConfigContext } from "./AppConfigContext";
import { AuthModel } from "../api/auth/AuthModel";
import {
  LoginForm,
  PortalCustomer,
  PortalResponse,
  PortalToken,
  User,
} from "../api/auth/Auth.typed";
import { useAlert } from "../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { ZekayCustomer } from "../api/zekay/Zekay.typed";

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType {
  authClient: AuthAdapter;
  loginCallback(form: LoginForm): Promise<void>;
  logoutCallback(): Promise<void>;
  isLogged: boolean;
  user: User | undefined;
  resetRequest(form: LoginForm): Promise<void>;
  resetPassword(form: LoginForm): Promise<void>;
  getUsers(): Promise<User[] | undefined>;
  inviteRequest(form: LoginForm): Promise<void>;
  createInvitedUser(form: LoginForm): Promise<void>;
  loginPortal: (
    customer: PortalCustomer,
    creditId: string
  ) => Promise<PortalToken | undefined>;
  getPortalCustomerInfo: (token: string) => Promise<PortalResponse | undefined>;
  updatePortalCustomerInfo: (
    cus: ZekayCustomer,
    token: string
  ) => Promise<void>;
  isAdmin: () => boolean;
  verifyToken(form: LoginForm): Promise<boolean | undefined>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider(props: AuthProviderProps) {
  const appConfig = React.useContext(AppConfigContext);

  const authModel = new AuthModel(appConfig!);
  const authClient = new AuthAdapter(authModel);

  const intervalMe = React.useRef<NodeJS.Timeout>();

  const [isLogged, setIsLogged] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User>();

  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const loginCallback = async (form: LoginForm) => {
    try {
      await authClient.login(form).then((u: User) => {
        localStorage.setItem("userSession", JSON.stringify(u));
        setUser(u);
        setIsLogged(true);
      });
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const logoutCallback = async () => {
    try {
      await authClient.logout().then(() => {
        localStorage.removeItem("userSession");
        setUser(undefined);
        setIsLogged(false);
      });
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const resetPassword = async (form: LoginForm) => {
    try {
      await authClient
        .resetPassword(form)
        .then(() => showAlert("Mot de passe modifié !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const resetRequest = async (form: LoginForm) => {
    try {
      await authClient
        .resetRequest(form)
        .then(() => showAlert("Demande reçue !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getUsers = async (): Promise<User[] | undefined> => {
    try {
      return await authClient.getUsers();
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const inviteRequest = async (form: LoginForm) => {
    try {
      await authClient
        .inviteRequest(form)
        .then(() => showAlert("Invitation envoyée!", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const createInvitedUser = async (form: LoginForm) => {
    try {
      await authClient
        .createInvitedUser(form)
        .then(() => showAlert("Mot de passe créé !", "success"));
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const loginPortal = async (
    customer: PortalCustomer,
    creditId: string
  ): Promise<PortalToken | undefined> => {
    try {
      return await authClient.loginPortal(customer, creditId);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const getPortalCustomerInfo = async (
    token: string
  ): Promise<PortalResponse | undefined> => {
    return await authClient.getPortalCustomerInfo(token);
  };

  const updatePortalCustomerInfo = async (
    cus: ZekayCustomer,
    token: string
  ): Promise<void> => {
    try {
      await authClient.updatePortalCustomerInfo(cus, token);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  const verifyToken = async (form: LoginForm): Promise<boolean | undefined> => {
    try {
      return await authClient.verifyToken(form);
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
        return false;
      }
    }
  };

  const isAdmin = (): boolean => {
    return user?.Role === "Administrateur";
  };

  React.useEffect(() => {
    if (intervalMe !== undefined) {
      clearInterval(intervalMe.current);
    }

    const meCallback = async () => {
      try {
        await authClient.me().then((u: User) => {
          if (u.Name === undefined) {
            localStorage.removeItem("userSession");
            setIsLogged(false);
            navigate("/login");
            showAlert("Vous avez été déconnecté", "warning");
          } else {
            setUser(u);
            setIsLogged(true);
          }
        });
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
    };

    const u = localStorage.getItem("userSession");
    if (u) {
      meCallback();
    }

    intervalMe.current = setInterval(() => {
      const u = localStorage.getItem("userSession");
      if (u) {
        meCallback();
      }
    }, 60000);
  }, []);

  const contextValue = {
    authClient,
    loginCallback,
    logoutCallback,
    isLogged,
    user,
    resetPassword,
    resetRequest,
    getUsers,
    inviteRequest,
    createInvitedUser,
    loginPortal,
    getPortalCustomerInfo,
    updatePortalCustomerInfo,
    isAdmin,
    verifyToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
}
