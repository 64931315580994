import React from "react";
import { Box, Button, Typography, CircularProgress } from "@mui/joy";
import { saveAsCSV } from "../../../utils/fileSaver";
import useZekay from "../../../contexts/controllers/useZekay";

export default function ProductComponent() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getProductsCsv } = useZekay();

  const handleProducts = async () => {
    setIsLoading(true);
    getProductsCsv()
      .then((data: string | undefined) => {
        if (data) saveAsCSV(data, "products");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ width: "100%", textAlign: "start", padding: 2 }}>
      <Typography level="h4" fontWeight="lg" marginBottom={2} sx={{ color: "#FCCB06" }}>
        Produits
      </Typography>
      <Button
        onClick={handleProducts}
        color="primary"
        variant="solid"
        fullWidth
        sx={{ height: 48, fontWeight: "md" }}
        disabled={isLoading}
        startDecorator={isLoading && <CircularProgress size="sm" />}
      >
        Exporter les produits (CSV)
      </Button>
    </Box>
  );
}
