import React from "react";
import { Box, Divider, Typography, Tooltip, IconButton } from "@mui/joy";
import { SBackground, SUserAdd } from "../../../styles/StyledMUI";
import useAuth from "../../../contexts/controllers/useAuth";
import { User } from "../../../api/auth/Auth.typed";
import UserInviteComponent from "./display/UserInviteComponent";
import UserItem from "./display/UserItem";

export default function UserList() {
  const { getUsers } = useAuth();
  const [users, setUsers] = React.useState<User[]>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    getUsers().then((us) => setUsers(us));
  }, []);

  return (
    <Box sx={{ width: "100%", padding: 2, height: "100%" }}>
      <Typography
        level="h4"
        fontWeight="lg"
        marginBottom={2}
        sx={{ color: "#FCCB06" }}
      >
        Utilisateurs
      </Typography>
      <IconButton
        onClick={() => setIsOpen(true)}
        sx={{ position: "absolute", right: "40px" }}
      >
        <Tooltip title="Inviter utilisateur">
          <SUserAdd />
        </Tooltip>
      </IconButton>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Table Header */}
      <Box sx={{ display: "flex", paddingY: 1 }}>
        <Box sx={{ width: "33%", paddingX: 1 }}>
          <Typography level="h4" fontWeight="bold">
            Prénom
          </Typography>
        </Box>
        <Box sx={{ width: "33%", paddingX: 1 }}>
          <Typography level="h4" fontWeight="bold">
            Email
          </Typography>
        </Box>
        <Box sx={{ width: "33%", paddingX: 1 }}>
          <Typography level="h4" fontWeight="bold">
            Rôle
          </Typography>
        </Box>
      </Box>

      {/* Table Body */}
      {users?.map((user) => (
        <UserItem key={user.ID} user={user} />
      ))}

      {/* User Invitation Modal */}
      {isOpen && (
        <SBackground>
          <UserInviteComponent setIsOpen={setIsOpen} />
        </SBackground>
      )}
    </Box>
  );
}
