import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Card, Typography } from "@mui/joy";
import SchoolIcon from "@mui/icons-material/School";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Settings from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import Zekay from "../../styles/images/zekay_icon.svg";
import AccountMenu from "../nav/AccountBox";
import useAuth from "../../contexts/controllers/useAuth";

const MenuPage: React.FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const handleTileClick = (path: string) => navigate(path);

  const tiles = [
    {
      path: "/customers",
      label: "Clients",
      icon: <PeopleIcon sx={{ color: "black" }} />,
    },
    {
      path: "/trainings",
      label: "Formations",
      icon: <SchoolIcon sx={{ color: "black" }} />,
    },
    {
      path: "/finance",
      label: "Comptabilité",
      icon: <AttachMoney sx={{ color: "black" }} />,
    },
    {
      path: "/settings",
      label: "Paramètres",
      icon: <Settings sx={{ color: "black" }} />,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
        padding: 2, // For responsiveness
      }}
    >
      {/* Logo */}
      <Box display="flex" alignItems="center" mb={4}>
        <img src={Zekay} height="80px" alt="Logo" />
        <Typography
          mt={1}
          marginLeft={1}
          sx={{
            fontFamily: "monospace",
            fontSize: { xs: "20px", sm: "25px" },
            letterSpacing: ".1rem",
            display: { xs: "none", sm: "block" },
          }}
        >
          Zékay
        </Typography>
      </Box>

      {/* Tiles */}
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        {tiles.map((tile, index) =>
          (tile.path !== "/customers" && isAdmin()) ||
          tile.path === "/customers" ? (
            <Grid key={index}>
              <Card
                onClick={() => handleTileClick(tile.path)}
                variant="soft"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: "lg",
                  height: { xs: 120, sm: 140, md: 160 }, // Responsiveness
                  width: { xs: 120, sm: 140, md: 160 },
                  cursor: "pointer",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                    backgroundColor: "#FCCB06",
                  },
                }}
              >
                <Box marginTop={2}>{tile.icon}</Box>
                <Typography
                  component="h1"
                  mt={1}
                  fontSize={{ xs: "0.9rem", sm: "1rem" }}
                >
                  {tile.label}
                </Typography>
              </Card>
            </Grid>
          ) : null
        )}
      </Grid>

      {/* Account Menu */}
      <Box mt={4}>
        <AccountMenu />
      </Box>
    </Box>
  );
};

export default MenuPage;
