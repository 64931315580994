import { ZekayCustomer } from "../zekay/Zekay.typed";
import { LoginForm, PortalCustomer } from "./Auth.typed";
import { AuthModel } from "./AuthModel";

export class AuthAdapter {
  authModel: AuthModel;

  constructor(authModel: AuthModel) {
    this.authModel = authModel;
  }

  async login(form: LoginForm) {
    return await this.authModel.login(form);
  }

  async resetRequest(form: LoginForm) {
    return await this.authModel.resetRequest(form);
  }

  async resetPassword(form: LoginForm) {
    return await this.authModel.resetPassword(form);
  }

  async logout() {
    return await this.authModel.logout();
  }

  async me() {
    return await this.authModel.me();
  }

  async getUsers() {
    return await this.authModel.getUsers();
  }

  async inviteRequest(form: LoginForm) {
    return await this.authModel.inviteRequest(form);
  }

  async createInvitedUser(form: LoginForm) {
    return await this.authModel.createInvitedUser(form);
  }

  async loginPortal(customer: PortalCustomer, creditId: string) {
    return await this.authModel.loginPortal(customer, creditId);
  }

  async getPortalCustomerInfo(token: string) {
    return await this.authModel.getPortalCustomerInfo(token);
  }

  async updatePortalCustomerInfo(cus: ZekayCustomer, token: string) {
    return await this.authModel.updatePortalCustomerInfo(cus, token);
  }

  async verifyToken(form: LoginForm) {
    return await this.authModel.verifyToken(form);
  }
}
