import React, { Dispatch, SetStateAction } from "react";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@mui/joy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { base64ToBlob, convertBase64 } from "../../../utils/fileSaver";

type FileComponentProps = {
  file: any;
  setFile: Dispatch<
    SetStateAction<string | Blob | File | ProgressEvent<FileReader> | undefined>
  >;
  title: string | undefined;
};

export default function FileComponent(props: FileComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await convertBase64(file);
      props.setFile(base64);
    }
    setIsLoading(false);
  };

  const viewFile = () => {
    if (props.file) {
      const fileBlob = base64ToBlob(props.file);
      const fileURL = URL.createObjectURL(fileBlob);
      const pdfWindow = window.open("");
      pdfWindow?.document.write(
        `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
      );
    }
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {!isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={uploadFile}
                  accept="application/pdf"
                />
                <Tooltip title="Ajouter document">
                  <IconButton component="span" size="lg" color="primary">
                    <Avatar
                      variant="soft"
                      size="sm"
                      sx={{
                        backgroundColor: "transparent",
                      }}
                    >
                      +
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </label>
              {props.file && (
                <Tooltip title="Voir le document">
                  <IconButton onClick={viewFile} size="lg" color="primary">
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {props.file ? (
              <Box display="flex" alignItems="center" gap={1}>
                <Typography fontSize="sm" color="success">
                  Document renseigné
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap={1}>
                <Typography fontSize="sm" color="danger">
                  Document non renseigné
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <CircularProgress size="lg" />
        )}
      </Box>
    </Box>
  );
}
