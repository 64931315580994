import { createGlobalStyle } from "styled-components";
import { extendTheme } from "@mui/joy";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: black;
    font-family: "Roboto", sans-serif;
    overflow: auto;
  }
`;

export const zekayTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          body: "black",
        },
        text: {
          primary: "#FFFFFF", // Définit le texte principal en blanc
        },
      },
    },
    dark: {
      palette: {
        background: {
          body: "black",
        },
        text: {
          primary: "#FFFFFF", // Définit le texte principal en blanc
        },
      },
    },
  },
  typography: {
    h1: {
      color: "#FFFFFF",
    },
    h2: {
      color: "#FFFFFF",
    },
    h3: {
      color: "#FFFFFF",
    },
    h4: {
      color: "#FFFFFF",
      fontSize: "14px",
    },
    // Applique cette couleur par défaut à toutes les variantes de Typography
  },
  fontFamily: {
    body: '"Public Sans", sans-serif',
  },
});
