import React from "react";
import { Box, Typography, IconButton, Divider } from "@mui/joy";
import { AuthContext } from "../../contexts/AuthContext";
import { useAlert } from "../../hooks/useAlert";
import { SLogout } from "../../styles/StyledMUI";

export default function AccountBox() {
  const { user, logoutCallback } = React.useContext(AuthContext);
  const { showAlert } = useAlert();

  const handleLogout = async () => {
    try {
      await logoutCallback();
      showAlert("A bientôt !", "success");
    } catch (err) {
      if (err instanceof Error) {
        showAlert(err.message, "danger");
      }
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "var(--Sidebar-width, 170px)",
        padding: 2,
      }}
    >
      <Divider sx={{ marginBottom: 1, backgroundColor: "whitesmoke" }} />
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box>
          <Typography sx={{ color: "whitesmoke" }}>
            {user?.Name || "Utilisateur"}
          </Typography>
        </Box>
        <IconButton
          onClick={handleLogout}
          size="sm"
          variant="plain"
          sx={{ marginLeft: "auto" }}
        >
          <SLogout />
        </IconButton>
      </Box>
    </Box>
  );
}
