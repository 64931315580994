import React from "react";
import {
  Button,
  IconButton,
  Input,
  CircularProgress,
  Typography,
} from "@mui/joy";
import { Box } from "@mui/system";
import Zekay from "../../styles/images/zekay_logo.svg";
import Chrome from "../../styles/images/Google Chrome Black.svg";
import { useAlert } from "../../hooks/useAlert";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function LoginPage() {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { loginCallback, isLogged, user } = React.useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleLogin = async () => {
    if (email && password) {
      setIsLoading(true);
      try {
        await loginCallback({ Email: email, Password: password });
      } catch (err) {
        if (err instanceof Error) showAlert(err.message, "danger");
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isLogged) {
      showAlert("Bienvenue " + user?.Name + " !", "success");
      navigate("/menu");
    }
  }, [isLogged]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        padding: 2,
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: 0,
          width: "100%",
          textAlign: "center",
          padding: "8px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "4px",
          }}
        >
          <Typography level="h4" fontSize={11} fontStyle={"italic"}>
            Optimisé pour les navigateurs Chrome
          </Typography>
          <img
            src={Chrome}
            width="30px"
            alt="Logo Chrome"
            style={{
              filter: "invert(1)",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          backgroundColor: "background.body",
          padding: 3,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", // Ajout d'une ombre pour l'esthétique
        }}
      >
        {/* Logo */}
        <Box
          sx={{ display: "flex", justifyContent: "center", marginBottom: 3 }}
        >
          <img src={Zekay} width="200px" alt="Logo" />
        </Box>

        {/* Email Input */}
        <Input
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          sx={{ marginY: 1 }}
          onKeyDown={(e) => e.key === "Enter" && handleLogin()}
        />

        {/* Password Input */}
        <Input
          placeholder="Mot de passe"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleLogin()}
          endDecorator={
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
          sx={{ marginY: 1 }}
        />

        {/* Mot de passe oublié */}
        <Button
          variant="plain"
          size="sm"
          onClick={() => navigate("/request")}
          sx={{ fontSize: "0.8rem", marginY: 1 }}
        >
          Mot de passe oublié ?
        </Button>

        {/* Bouton de connexion */}
        <Button
          variant="solid"
          color="primary"
          sx={{ marginTop: 2, width: "100%" }}
          onClick={handleLogin}
          disabled={isLoading || !email || !password}
        >
          {isLoading ? <CircularProgress size="sm" /> : "Se connecter"}
        </Button>
      </Box>
    </Box>
  );
}
