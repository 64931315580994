import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { Box, FormControl, Select, Option, Typography } from "@mui/joy";
import {
  ExcursionsStats,
  StatDate,
  StatsHandler,
} from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import { useAlert } from "../../../../hooks/useAlert";
import { formatMonth } from "../../../../utils/dateTime";

export default function BoatCustomerCount() {
  const [dates, setDates] = React.useState<StatDate[]>();
  const [year, setYear] = React.useState<string>("");
  const [excursionsStats, setExcursionsStats] =
    React.useState<ExcursionsStats[]>();
  const { showAlert } = useAlert();
  const { getStatDates, getExcursionsStatsByYear } = useZekay();

  React.useEffect(() => {
    getStatDates()
      .then((dates) => setDates(dates))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "danger");
        }
      });
  }, []);

  const handleChangeByYear = (newValue: string) => {
    setYear(newValue);

    const date: StatsHandler = {
      Year: Number(newValue),
      Month: 0,
    };

    getExcursionsStatsByYear(date)
      .then((stats) => setExcursionsStats(stats))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "danger");
        }
      });
  };

  return (
    <React.Fragment>
      <Typography level="h4" mb={2} sx={{ color: "#FCCB06" }}>
        Taux d'occupation du bateau
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <Select
          placeholder="Année"
          value={year}
          onChange={(event, newValue) => handleChangeByYear(newValue as string)}
        >
          {dates?.map((date) => (
            <Option key={date.Year} value={date.Year.toString()}>
              {date.Year}
            </Option>
          ))}
        </Select>
      </Box>
      <Box padding={3}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={excursionsStats}
            margin={{ top: 20, right: 30, left: 10, bottom: 5 }}
          >
            <Line
              type="monotone"
              dataKey="UserCountAvg"
              stroke="blue"
              name="Moniteurs"
            />
            <Line
              type="monotone"
              dataKey="CustomerCountAvg"
              stroke="green"
              name="Clients"
            />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="Month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
