import React, { ReactNode, useRef, createContext } from "react";
import { Box, Button, Modal, Typography, IconButton } from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useModalShow } from "../hooks/useConfirmationModal";

type ConfirmationModalProviderProps = {
  children: ReactNode;
};

export type ModalContextType = {
  showConfirmation: (
    title: string,
    message?: string,
    element?: JSX.Element
  ) => Promise<boolean>;
};

export const ConfirmationModalContext = createContext<ModalContextType>(null!);

export function ConfirmationModalProvider({
  children,
}: ConfirmationModalProviderProps) {
  const { setShow, show, onHide } = useModalShow();
  const [content, setContent] = React.useState<{
    title: string;
    message?: string;
    element?: JSX.Element;
  } | null>(null);
  const resolver = useRef<Function>();

  const handleShow = (
    title: string,
    message?: string,
    element?: JSX.Element
  ): Promise<boolean> => {
    setContent({ title, message, element });
    setShow(true);
    return new Promise((resolve) => (resolver.current = resolve));
  };

  const modalContext: ModalContextType = { showConfirmation: handleShow };

  const handleYes = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {children}
      <Modal open={show} onClose={onHide}>
        <Box
          sx={{
            maxWidth: 500,
            bgcolor: "background.surface",
            p: 3,
            borderRadius: "md",
            boxShadow: "lg",
            mx: "auto",
            my: "10%",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography component="h5">{content?.title}</Typography>
            <IconButton onClick={onHide}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Typography
            mb={2}
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {content?.message}
          </Typography>
          {content?.element}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            <Button variant="solid" color="primary" onClick={handleYes}>
              Continuer
            </Button>
            <Button variant="soft" onClick={handleCancel}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
}
