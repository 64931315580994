import React from "react";
import CardValidationItem from "./cardValidationItem";
import { Box, Typography, CircularProgress } from "@mui/joy";
import { Training } from "../../../api/zekay/Zekay.typed";
import useZekay from "../../../contexts/controllers/useZekay";
import CardEmptyList from "./cardEmptyList";

export default function CardValidationList() {
  const [trainings, setTrainings] = React.useState<Training[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getDoneTrainings } = useZekay();

  React.useEffect(() => {
    setIsLoading(true);
    getDoneTrainings()
      .then((t) => setTrainings(t || []))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", padding: 2 }}>
        <Typography level="h4" mb={2} sx={{ color: "#FCCB06" }}>
          {trainings && trainings.length > 0 && !isLoading
            ? "Cartes : " + trainings.length
            : "Cartes"}
        </Typography>

        {isLoading && (
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
            <CircularProgress />
          </Box>
        )}

        <table style={{ width: "100%", borderSpacing: "0 15px" }}>
          <thead>
            <tr>
              {[
                "Prénom",
                "Nom",
                "Organisation",
                "Qualification",
                "Commentaires",
                "Sessions théoriques",
                "Docs",
                "Résultats QCM",
                "Profil",
                "Mail",
                "Zuurit",
                "Cartes",
                "Valider",
              ].map((header, index) => (
                <th
                  key={index}
                  style={{
                    textAlign: "left",
                    paddingBottom: "10px",
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  <Typography level="h4" fontWeight="lg">
                    {header}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {trainings && trainings.length > 0
              ? trainings.map((t) => (
                  <CardValidationItem
                    training={t}
                    key={t.ID}
                    setTrainings={setTrainings}
                  />
                ))
              : trainings?.length === 0 && (
                  <tr>
                    <td colSpan={12}>
                      <CardEmptyList />
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </Box>
    </>
  );
}
