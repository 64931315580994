import React from "react";

export type AppConfig = {
  zekayAPI: string;
};

export function isValidAppConfig(appConfig: any): appConfig is AppConfig {
  return appConfig.zekayAPI !== "";
}

export const AppConfigContext = React.createContext<AppConfig>(null!);
