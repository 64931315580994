import { Outlet, useNavigate } from "react-router-dom";
import { ZekayProvider } from "../contexts/ZekayContext";
import { AuthContext } from "../contexts/AuthContext";
import React from "react";

export default function Home() {
  const { isLogged } = React.useContext(AuthContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLogged) {
      navigate("/login");
    }
  }, [isLogged]);

  return (
    <ZekayProvider>
      <Outlet />
    </ZekayProvider>
  );
}
