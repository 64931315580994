import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ZekayCustomer } from "../../api/zekay/Zekay.typed";
import { Box, Input } from "@mui/joy";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import useZekay from "../../contexts/controllers/useZekay";

type SearchBarProps = {
  searchList: ZekayCustomer[] | undefined;
  setSearchList: Dispatch<SetStateAction<ZekayCustomer[] | undefined>>;
};

export default function SearchBar({
  searchList,
  setSearchList,
}: SearchBarProps) {
  const [searchInput, setSearchInput] = React.useState<string>("");
  const { customers } = useZekay();

  React.useEffect(() => {
    if (searchInput.length > 2) {
      setSearchList(forwardList(searchInput));
    } else {
      setSearchList([]);
    }
  }, [searchInput, customers]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchInput(e.target.value);
  };

  const forwardList = (value: string) => {
    return customers?.filter((c: ZekayCustomer) =>
      c.LastName?.toLocaleLowerCase().startsWith(value.toLocaleLowerCase())
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: "15px",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        alignItems: "center",
        zIndex: 12,
        width: { xs: "50%", sm: "50%", md: "40%" },
      }}
    >
      <Input
        placeholder="Rechercher client"
        value={searchInput}
        onChange={handleChange}
        fullWidth
        startDecorator={<PersonSearchIcon />}
        sx={{
          width: "100%",
        }}
      />
    </Box>
  );
}
