import React from "react";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";

interface CustomMenuProps {
  items: string[];
  onItemClick: (item: string) => void;
  triggerLabel: React.ReactNode;
}

const CustomMenu: React.FC<CustomMenuProps> = ({
  items,
  onItemClick,
  triggerLabel,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = React.useCallback(
    (event: React.SyntheticEvent | null, isOpen: boolean) => {
      setOpen(isOpen);
    },
    []
  );

  return (
    <Dropdown open={open} onOpenChange={handleOpenChange}>
      <MenuButton sx={{ border: "none" }}>{triggerLabel}</MenuButton>
      <Menu>
        {items.map((item, index) => (
          <MenuItem key={index} onClick={() => onItemClick(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default CustomMenu;
