import React from "react";

import { AuthContext } from "../AuthContext";

export default function useAuth() {
  const {
    getUsers,
    inviteRequest,
    createInvitedUser,
    user,
    loginPortal,
    getPortalCustomerInfo,
    updatePortalCustomerInfo,
    isAdmin,
    verifyToken,
  } = React.useContext(AuthContext);

  return {
    getUsers,
    inviteRequest,
    createInvitedUser,
    user,
    loginPortal,
    getPortalCustomerInfo,
    updatePortalCustomerInfo,
    isAdmin,
    verifyToken,
  };
}
