import { useNavigate } from "react-router-dom";
import { Typography, Container, IconButton, Sheet } from "@mui/joy";
import Zekay from "../../styles/images/zekay_icon.svg";

function NavBar() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/menu");
  };

  return (
    <Sheet
      component="nav"
      sx={{
        position: "fixed",
        width: "100%",
        backgroundColor: "#000",
        height: "64px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={handleLogoClick}
          sx={{ padding: 0, marginRight: 1 }}
        >
          <img src={Zekay} height="20px" alt="Logo" />
        </IconButton>
        <Typography
          onClick={handleLogoClick}
          sx={{
            cursor: "pointer",
            fontFamily: "monospace",
            fontSize: { xs: "15px", sm: "20px" },
            letterSpacing: ".1rem",
            mr: 1,
            display: { xs: "none", sm: "block" },
            color: "white",
          }}
        >
          Zékay
        </Typography>
      </Container>
    </Sheet>
  );
}

export default NavBar;
