import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Grid, Typography, CircularProgress } from "@mui/joy";
import { saveAsCSV } from "../../../utils/fileSaver";
import useZekay from "../../../contexts/controllers/useZekay";
import { InvoicesDate } from "../../../api/zekay/Zekay.typed";
import { useAlert } from "../../../hooks/useAlert";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const today = dayjs().format("YYYY-MM-DD");

export default function InvoicesComponent() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");
  const { getInvoicesCsv } = useZekay();
  const { showAlert } = useAlert();

  const handleInvoices = async () => {
    if (startDate && endDate) {
      setIsLoading(true);
      const period: InvoicesDate = {
        Start: dayjs(startDate).toISOString(),
        End: dayjs(endDate).toISOString(),
      };
      getInvoicesCsv(period)
        .then((data) => data && saveAsCSV(data, "invoices"))
        .finally(() => setIsLoading(false));
    } else {
      showAlert("Veuillez sélectionner une date de début et de fin", "danger");
    }
  };

  return (
    <Box sx={{ width: "100%", textAlign: "start", p: 2 }}>
      <Typography level="h4" fontWeight="lg" mb={2} sx={{ color: "#FCCB06" }}>
        Factures
      </Typography>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        maxDate={today}
      />
      <Button
        onClick={handleInvoices}
        color="primary"
        variant="solid"
        fullWidth
        sx={{ mt: 2, height: 48 }}
        startDecorator={isLoading && <CircularProgress size="sm" />}
        disabled={isLoading}
      >
        Exporter les factures (CSV)
      </Button>
    </Box>
  );
}

interface DatePickerRangeProps {
  startDate: string;
  endDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  maxDate: string; // Ajout de maxDate dans les props
}

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxDate,
}: DatePickerRangeProps) => (
  <Grid container spacing={2} sx={{ width: "100%", mb: 2 }}>
    <Grid xs={6}>
      <Box
        component="input" // Utilisation de `Box` pour styliser un input natif
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        max={maxDate}
        sx={{
          width: "100%",
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "neutral.outlinedBorder",
        }}
      />
    </Grid>
    <Grid xs={6}>
      <Box
        component="input" // Utilisation de `Box` pour styliser un input natif
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        max={maxDate}
        sx={{
          width: "100%",
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "neutral.outlinedBorder",
        }}
      />
    </Grid>
  </Grid>
);
