import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  CreateNewFolder as CreateNewFolderIcon,
  Verified as VerifiedIcon,
  Done as DoneIcon,
  Quiz as QuizIcon,
  Refresh as RefreshIcon,
  AddAPhoto as AddAPhotoIcon,
  Download as DownloadIcon,
  PlaylistAddCircle as PlaylistAddCircleIcon,
  AssignmentLate as AssignmentLateIcon,
  AccountCircle as AccountCircleIcon,
  NoPhotography as NoPhotographyIcon,
  School as SchoolIcon,
  RocketLaunch as RocketLaunchIcon,
  ShoppingCart as ShoppingCartIcon,
  CreditCard as CreditCardIcon,
  ForwardToInbox as ForwardToInboxIcon,
  QueryStats as QueryStatsIcon,
  DeleteForever as DeleteForeverIcon,
  Save as SaveIcon,
  Group as GroupIcon,
  PersonAdd as PersonAddIcon,
  Launch as LaunchIcon,
  Notes as NotesIcon,
  Info as InfoIcon,
  FilePresent as FilePresentIcon,
  AttachMoney as AttachMoneyIcon,
  QuestionMark as QuestionMarkIcon,
  AccessTime as AccessTimeIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
  FileOpen as FileOpenIcon,
  Analytics as AnalyticsIcon,
  Dvr as DvrIcon,
  DisplaySettings as DisplaySettingsIcon,
  AddToPhotos as AddToPhotosIcon,
  AddBox as AddBoxIcon,
  Groups as GroupsIcon,
  Add as AddIcon,
  Home as HomeRoundedIcon,
  Logout as LogoutRoundedIcon,
} from "@mui/icons-material";

// Utility function to create dynamic icon components
const createDynamicIcon = (Icon: React.ComponentType) =>
  MuiStyled(Icon)<{ iconColor?: string }>(({ theme, iconColor }) => ({
    color: iconColor || "#FCCB06", // Default to "inherit" if no color provided
    cursor: "pointer",
    "&:hover": {
      color: iconColor ? "lightgrey" : "inherit",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "scale(0.8, 0.8)",
    },
  }));

// Icons
export const SClose = createDynamicIcon(CloseIcon);
export const SEdit = createDynamicIcon(EditIcon);
export const SCreate = createDynamicIcon(AddIcon);
export const SValidate = createDynamicIcon(VerifiedIcon);
export const SDone = createDynamicIcon(DoneIcon);
export const SResultOK = createDynamicIcon(QuizIcon);
export const SResultKO = createDynamicIcon(QuizIcon);
export const SQuizz = createDynamicIcon(AssignmentLateIcon);
export const SRefresh = createDynamicIcon(RefreshIcon);
export const SAddPhoto = createDynamicIcon(AddAPhotoIcon);
export const SNoPhoto = createDynamicIcon(NoPhotographyIcon);
export const SDownloadPhoto = createDynamicIcon(DownloadIcon);
export const SAddDocument = createDynamicIcon(PlaylistAddCircleIcon);
export const SAddCriteria = createDynamicIcon(AddToPhotosIcon);
export const SAddModule = createDynamicIcon(AddBoxIcon);
export const SDownload = createDynamicIcon(DownloadIcon);
export const SView = createDynamicIcon(VisibilityIcon);
export const SCreateFolder = createDynamicIcon(CreateNewFolderIcon);
export const SAnalytics = createDynamicIcon(AnalyticsIcon);
export const SMissing = createDynamicIcon(CloseIcon);
export const SMissingFile = createDynamicIcon(QuestionMarkIcon);
export const SReset = createDynamicIcon(CloseIcon);
export const SSchool = createDynamicIcon(SchoolIcon);
export const SArrow = createDynamicIcon(SubdirectoryArrowRightIcon);
export const SRocket = createDynamicIcon(RocketLaunchIcon);
export const SProduct = createDynamicIcon(ShoppingCartIcon);
export const SInvoice = createDynamicIcon(AttachMoneyIcon);
export const SSettings = createDynamicIcon(DisplaySettingsIcon);
export const STemplates = createDynamicIcon(DvrIcon);
export const SCreditCard = createDynamicIcon(CreditCardIcon);
export const SSendMail = createDynamicIcon(ForwardToInboxIcon);
export const SStats = createDynamicIcon(QueryStatsIcon);
export const SUsers = createDynamicIcon(GroupIcon);
export const SGroups = createDynamicIcon(GroupsIcon);
export const SAddOption = createDynamicIcon(AddIcon);
export const SDelete = createDynamicIcon(DeleteForeverIcon);
export const SSave = createDynamicIcon(SaveIcon);
export const SFile = createDynamicIcon(FilePresentIcon);
export const SDate = createDynamicIcon(AccessTimeIcon);
export const SInfo = createDynamicIcon(InfoIcon);
export const SUserAdd = createDynamicIcon(PersonAddIcon);
export const SRedirect = createDynamicIcon(LaunchIcon);
export const SNotes = createDynamicIcon(NotesIcon);
export const SGenerate = createDynamicIcon(FileOpenIcon);
export const SOpenProfile = createDynamicIcon(AccountCircleIcon);
export const SHome = createDynamicIcon(HomeRoundedIcon);
export const SLogout = createDynamicIcon(LogoutRoundedIcon);

// Custom styled components
export const SBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: "calc(100vh - 64px)";
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SPhoto = styled.img`
  width: 100%;
  border-radius: 50%;
`;
