import React from "react";
import CustomersList from "./display/CustomersList";
import { ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import SearchBar from "../../nav/SearchBar";
import { Box } from "@mui/joy";
import Escapade from "../../../styles/images/logo_escapade-long-plein_RVB_Blanc.svg";
import NavBar from "../../nav/NavBar";

export default function CustomersPage() {
  const [searchList, setSearchList] = React.useState<
    ZekayCustomer[] | undefined
  >(undefined);

  return (
    <>
      <NavBar />
      <SearchBar searchList={searchList} setSearchList={setSearchList} />
      <Box
        sx={{
          position: "fixed",
          height: "calc(100vh - 64px)",
          width: "100vw",
          top: 64,
          left: 0,
          overflow: "auto",
          zIndex: 10,
          padding: "1rem",
        }}
      >
        <CustomersList customers={searchList} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
          opacity: 0.7,
        }}
      >
        <img
          src={Escapade}
          style={{ width: "100%", maxWidth: "300px", height: "auto" }}
          alt="Logo"
        />
      </Box>
    </>
  );
}
