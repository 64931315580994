import { AppConfig } from "../../contexts/AppConfigContext";
import { ZekayCustomer } from "../zekay/Zekay.typed";
import {
  LoginForm,
  PortalCustomer,
  PortalResponse,
  PortalToken,
  User,
} from "./Auth.typed";

export class AuthModel {
  zekayAPI: string;

  constructor(config: AppConfig) {
    this.zekayAPI = config.zekayAPI;
  }

  async login(form: LoginForm): Promise<User> {
    return fetch(this.zekayAPI + "/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        const json = await response.json();
        if (response.status !== 200) {
          throw new Error(json.message);
        }
        return json;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async resetRequest(form: LoginForm): Promise<void> {
    return fetch(this.zekayAPI + "/auth/reset", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async resetPassword(form: LoginForm): Promise<void> {
    return fetch(this.zekayAPI + "/auth/reset/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 200) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async me(): Promise<User> {
    return fetch(this.zekayAPI + "/auth/user", {
      credentials: "include",
    })
      .then(async (response) => {
        const json = await response.json();
        return json;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async logout(): Promise<void> {
    return fetch(this.zekayAPI + "/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getUsers(): Promise<User[]> {
    return fetch(this.zekayAPI + "/auth/users", {
      credentials: "include",
    })
      .then(async (response) => {
        const json = await response.json();
        if (response.status !== 200) {
          throw new Error(json.message);
        }
        return json;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async inviteRequest(form: LoginForm): Promise<void> {
    return fetch(this.zekayAPI + "/auth/register/invite", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async createInvitedUser(form: LoginForm): Promise<void> {
    return fetch(this.zekayAPI + "/auth/register/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async loginPortal(
    customer: PortalCustomer,
    creditId: string
  ): Promise<PortalToken> {
    return fetch(this.zekayAPI + "/portal/login?creditId=" + creditId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customer),
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async getPortalCustomerInfo(token: string): Promise<PortalResponse> {
    return fetch(this.zekayAPI + "/portal/info", {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then(async (response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async updatePortalCustomerInfo(
    cus: ZekayCustomer,
    token: string
  ): Promise<void> {
    return fetch(this.zekayAPI + "/portal/info", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(cus),
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  async verifyToken(form: LoginForm): Promise<boolean> {
    return fetch(this.zekayAPI + "/auth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
      credentials: "include",
    })
      .then(async (response) => {
        if (response.status !== 204) {
          const json = await response.json();
          throw new Error(json.message);
          return false;
        }
        return true;
      })
      .catch((error) => {
        throw new Error(error);
        return false;
      });
  }
}
