import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  Radio,
  RadioGroup,
  Grid,
  Input,
  IconButton,
  Modal,
  Divider,
} from "@mui/joy";
import { Session } from "../../../api/zekay/Zekay.typed";
import { SClose, SSave } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { useAlert } from "../../../hooks/useAlert";
import useAuth from "../../../contexts/controllers/useAuth";

type SessionsFormProps = {
  session: Session;
  open: boolean;
  onClose: () => void;
  onSave: (updatedSession: Session) => void;
};

export default function SessionsForm({
  session,
  open,
  onClose,
  onSave,
}: SessionsFormProps) {
  const initialFormState = React.useMemo(() => session.Form, [session]);
  const [form, setForm] = React.useState(initialFormState);
  const [diveSite, setDiveSite] = React.useState(session.DiveSite);
  const [diveTime, setDiveTime] = React.useState(session.DiveTime);
  const [diveDepth, setDiveDepth] = React.useState(session.DiveDepth);
  const [globalComment, setGlobalComment] = React.useState(
    session.GlobalComment
  );
  const { showAlert } = useAlert();
  const { user } = useAuth();

  const [errors, setErrors] = React.useState({
    diveSite: false,
    diveTime: false,
    diveDepth: false,
  });

  const { updateSession } = useZekay();

  React.useEffect(() => {
    const sortedForm = { ...form };
    sortedForm.modules = sortedForm.modules.map((module: any) => ({
      ...module,
      criteria: sortCriteria(module.criteria),
    }));
    setForm(sortedForm);
  }, []);

  const handleCheckboxChange = (
    moduleIndex: number,
    criteriaIndex: number,
    option: string
  ) => {
    const updatedForm = { ...form };
    const selectedOptions =
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status || [];

    if (selectedOptions.includes(option)) {
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status =
        selectedOptions.filter((opt: string) => opt !== option);
    } else {
      updatedForm.modules[moduleIndex].criteria[criteriaIndex].status = [
        ...selectedOptions,
        option,
      ];
    }

    setForm(updatedForm);
  };

  const handleRadioChange = (
    moduleIndex: number,
    criteriaIndex: number,
    value: string
  ) => {
    const updatedForm = { ...form };
    updatedForm.modules[moduleIndex].criteria[criteriaIndex].status = [value];
    setForm(updatedForm);
  };

  const handleCommentChange = (
    moduleIndex: number,
    criteriaIndex: number,
    value: string
  ) => {
    const updatedForm = { ...form };
    updatedForm.modules[moduleIndex].criteria[criteriaIndex].comments_value =
      value;
    setForm(updatedForm);
  };

  const handleSave = () => {
    const newErrors = {
      diveSite: !diveSite,
      diveTime: !diveTime || diveTime <= 0,
      diveDepth: !diveDepth || diveDepth <= 0,
    };
    setErrors(newErrors);

    const hasError = Object.values(newErrors).some((error) => error);

    if (!hasError) {
      const updatedSession = {
        ...session,
        Form: form,
        DiveSite: diveSite,
        DiveTime: diveTime,
        DiveDepth: diveDepth,
        GlobalComment: globalComment,
        Instructor: user?.Name,
      };
      updateSession(updatedSession).then(() => {
        onSave(updatedSession);
        onClose();
      });
    } else {
      showAlert("Les paramètres de plongée sont vides", "danger");
    }
  };

  const handleCancel = () => {
    setForm(initialFormState);
    onClose();
  };

  // Fonction pour trier les critères : non rempli, orange, vert
  const sortCriteria = (criteria: any[]) => {
    return criteria.sort((a, b) => {
      const getPriority = (criteria: any) => {
        const selectedOptions = criteria.status || [];
        const isSingleChoice =
          criteria.options.length === 2 &&
          criteria.options.includes("OK") &&
          criteria.options.includes("A retravailler");

        if (isSingleChoice) {
          if (selectedOptions.length === 0) return 1; // Non rempli
          return selectedOptions.includes("OK") ? 3 : 2; // Vert pour "OK", Orange pour "A retravailler"
        } else {
          if (selectedOptions.length === 0) return 1; // Non rempli
          return selectedOptions.length === criteria.options.length ? 3 : 2; // Vert si tout est rempli, Orange sinon
        }
      };

      return getPriority(a) - getPriority(b);
    });
  };

  // Fonction pour déterminer la couleur et la visibilité de la barre
  const getBarColor = (criteria: any) => {
    const selectedOptions = criteria.status || [];
    const isSingleChoice =
      criteria.options.length === 2 &&
      criteria.options.includes("OK") &&
      criteria.options.includes("A retravailler");

    if (isSingleChoice) {
      if (selectedOptions.length === 0) return "none";
      return selectedOptions.includes("OK") ? "green" : "orange";
    } else {
      if (selectedOptions.length === 0) return "none";
      return selectedOptions.length === criteria.options.length
        ? "green"
        : "orange";
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Box
          sx={{
            position: "fixed",
            top: 10,
            left: 10,
            right: 10,
            height: "calc(100% - 30px)",
            overflow: "auto",
            borderRadius: "10px",
            padding: "1rem",
            backgroundColor: "white",
          }}
        >
          <Box>
            <Typography level="h3" sx={{ color: "black", marginBottom: 1 }}>
              {"Séance " + session.Number}
            </Typography>

            {/* Champs pour DiveSite, DiveTime et DiveDepth */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid xs={4}>
                <Input
                  placeholder="Site de plongée"
                  value={diveSite}
                  onChange={(e) => setDiveSite(e.target.value)}
                  error={errors.diveSite}
                />
                {errors.diveSite && (
                  <Typography level="h4" color="danger">
                    Ce champ est requis
                  </Typography>
                )}
              </Grid>
              <Grid xs={4}>
                <Input
                  placeholder="Profondeur de plongée (mètres)"
                  type="number"
                  value={diveDepth === 0 ? "" : diveDepth}
                  onChange={(e) => setDiveDepth(Number(e.target.value))}
                  error={errors.diveDepth}
                />
                {errors.diveDepth && (
                  <Typography level="h4" color="danger">
                    Ce champ est requis
                  </Typography>
                )}
              </Grid>
              <Grid xs={4}>
                <Input
                  placeholder="Temps de plongée (minutes)"
                  type="number"
                  value={diveTime === 0 ? "" : diveTime}
                  onChange={(e) => setDiveTime(Number(e.target.value))}
                  error={errors.diveTime}
                />
                {errors.diveTime && (
                  <Typography level="h4" color="danger">
                    Ce champ est requis
                  </Typography>
                )}
              </Grid>
              <Grid xs={12}>
                <Input
                  placeholder="Commentaire général"
                  value={globalComment}
                  onChange={(e) => setGlobalComment(e.target.value)}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />
            {form.modules.map((module: any, moduleIndex: number) => (
              <Box key={moduleIndex} mb={4}>
                <Typography level="h3" sx={{ color: "black", marginBottom: 1 }}>
                  {module.name}
                </Typography>

                {module.criteria.map((criteria: any, criteriaIndex: number) => {
                  const selectedOptions = criteria.status || [];
                  const isSingleChoice =
                    criteria.options.length === 2 &&
                    criteria.options.includes("OK") &&
                    criteria.options.includes("A retravailler");

                  return (
                    <Box
                      key={criteriaIndex}
                      mb={3}
                      sx={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {/* Barre verticale */}
                      <Box
                        sx={{
                          width: "5px",
                          height: "100px",
                          backgroundColor: getBarColor(criteria),
                          marginRight:
                            getBarColor(criteria) === "none" ? "0px" : "20px",
                          display:
                            getBarColor(criteria) === "none" ? "none" : "block",
                        }}
                      />

                      {/* Contenu du critère */}
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography
                          level="h4"
                          sx={{ color: "black", marginBottom: 1 }}
                        >
                          {criteria.description}
                        </Typography>

                        {isSingleChoice ? (
                          <RadioGroup
                            value={selectedOptions[0] || ""}
                            onChange={(e) =>
                              handleRadioChange(
                                moduleIndex,
                                criteriaIndex,
                                e.target.value
                              )
                            }
                          >
                            {criteria.options.map(
                              (option: string, optionIndex: number) => (
                                <Radio
                                  key={optionIndex}
                                  value={option}
                                  label={option}
                                  sx={{ color: "black", fontSize: "14px" }}
                                />
                              )
                            )}
                          </RadioGroup>
                        ) : (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}
                          >
                            {criteria.options.map(
                              (option: string, optionIndex: number) => (
                                <Box
                                  key={optionIndex}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    checked={selectedOptions.includes(option)}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        moduleIndex,
                                        criteriaIndex,
                                        option
                                      )
                                    }
                                  />
                                  <Typography
                                    fontSize="14px"
                                    sx={{ color: "black", marginLeft: 1 }}
                                  >
                                    {option}
                                  </Typography>
                                </Box>
                              )
                            )}
                          </Box>
                        )}

                        {criteria.comments && (
                          <Input
                            placeholder="Commentaires"
                            fullWidth
                            value={criteria.comments_value || ""}
                            onChange={(e) =>
                              handleCommentChange(
                                moduleIndex,
                                criteriaIndex,
                                e.target.value
                              )
                            }
                            sx={{ mt: 2 }}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "150px",
            height: "60px",
            backgroundColor: "#E0E0E0",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px 0 0 0",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid>
              <IconButton onClick={handleSave}>
                <SSave sx={{ color: "green" }} />
              </IconButton>
            </Grid>
            <Grid>
              <IconButton onClick={handleCancel}>
                <SClose sx={{ color: "red" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </>
    </Modal>
  );
}
