import { Box, IconButton } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SReset } from "../../../styles/StyledMUI";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

type BirthdateComponentProps = {
  birthdate: string | null | undefined; // API renvoie une chaîne de caractères
  setBirthdate: Dispatch<SetStateAction<string | null | undefined>>; // La date sera toujours une chaîne formatée
};

export default function BirthdateComponent(props: BirthdateComponentProps) {
  const maxDate = dayjs().format("YYYY-MM-DD");

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value; // La valeur entrée est déjà en ISO
    props.setBirthdate(dayjs(newDate).format("DD-MM-YYYY")); // Convertir en format API lors de la sauvegarde
  };

  const handleResetDate = () => {
    props.setBirthdate(null); // Réinitialiser à null
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          component="input"
          type="date"
          value={dayjs(props.birthdate, "DD-MM-YYYY", true).format(
            "YYYY-MM-DD"
          )} // Convertir en format ISO
          onChange={handleDateChange}
          max={maxDate}
          sx={{
            width: "100%",
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "neutral.outlinedBorder",
          }}
        />
        {props.birthdate && (
          <IconButton onClick={handleResetDate} sx={{ fontSize: "12px" }}>
            <SReset sx={{ color: "red" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
