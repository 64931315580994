import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Select,
  Option,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/joy";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  Module,
  TrainingTemplate,
  CourseTemplate,
} from "../../../../api/zekay/Zekay.typed";
import ModuleForm from "./ModuleForm";
import { SAddModule, SSave, SAddCriteria } from "../../../../styles/StyledMUI";

export default function CourseTemplateEditor() {
  const [templates, setTemplates] = React.useState<TrainingTemplate[]>();
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TrainingTemplate>();
  const [selectedSession, setSelectedSession] =
    React.useState<CourseTemplate>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    getTrainingsTemplates,
    getCourseTemplate,
    updateCourseTemplate,
    createCourseTemplate,
  } = useZekay();

  React.useEffect(() => {
    setIsLoading(true);
    getTrainingsTemplates()
      .then((t) => setTemplates(t || []))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChangeTemplate = async (newValue: string | null) => {
    const template = templates?.find((t) => t.CreditID.toString() === newValue);
    if (template) {
      setSelectedTemplate(template);
      setIsLoading(true);
      try {
        const session = await getCourseTemplate(template.CreditID);
        setSelectedSession(session);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCreateSession = async () => {
    if (selectedTemplate) {
      const newSession: CourseTemplate = {
        creditId: selectedTemplate.CreditID,
        version: 1,
        course: { modules: [] },
      };
      setIsLoading(true);
      try {
        await createCourseTemplate(newSession);
        const session = await getCourseTemplate(selectedTemplate.CreditID);
        setSelectedSession(session);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleModuleChange = (index: number, updatedModule: Module) => {
    if (selectedSession) {
      const updatedModules = [...selectedSession.course.modules];
      updatedModules[index] = updatedModule;
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: updatedModules,
        },
      });
    }
  };

  const handleDeleteModule = (index: number) => {
    if (selectedSession) {
      const updatedModules = [...selectedSession.course.modules];
      updatedModules.splice(index, 1);
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: updatedModules,
        },
      });
    }
  };

  const addModule = () => {
    if (selectedSession) {
      setSelectedSession({
        ...selectedSession,
        course: {
          ...selectedSession.course,
          modules: [
            ...selectedSession.course.modules,
            { name: "", criteria: [] },
          ],
        },
      });
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination || !selectedSession) return;

    const updatedModules = Array.from(selectedSession.course.modules);
    const [movedModule] = updatedModules.splice(result.source.index, 1);
    updatedModules.splice(result.destination.index, 0, movedModule);

    setSelectedSession({
      ...selectedSession,
      course: {
        ...selectedSession.course,
        modules: updatedModules,
      },
    });
  };

  const handleSaveSession = () => {
    if (selectedSession) {
      updateCourseTemplate(selectedSession);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Header avec Select et bouton de création */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <Select
          value={selectedTemplate?.CreditID.toString() || ""}
          onChange={(e, newValue) => handleChangeTemplate(newValue)}
          placeholder="Formation"
          sx={{ minWidth: "400px", fontSize: "15px" }}
        >
          {templates?.map((template) => (
            <Option
              key={template.CreditID}
              value={template.CreditID.toString()}
            >
              {template.Agency + " - " + template.Qualification}
            </Option>
          ))}
        </Select>
        {isLoading && <CircularProgress />}

        {/* Bouton + pour créer une session */}
        {selectedTemplate !== undefined && selectedSession === undefined && (
          <Tooltip title="Créer une nouvelle session">
            <IconButton
              sx={{
                marginLeft: "10px",
                backgroundColor: "primary.main",
                "&:hover": { backgroundColor: "primary.dark" },
              }}
              onClick={handleCreateSession}
            >
              <SAddCriteria sx={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider />

      {/* Liste des modules ou message */}
      {selectedSession ? (
        <Box
          sx={{
            marginTop: "20px",
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="modules">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedSession.course.modules.length > 0 ? (
                    selectedSession.course.modules.map((module, index) => (
                      <Draggable
                        key={index}
                        draggableId={`module-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ModuleForm
                              key={index}
                              module={module}
                              onChange={(updatedModule) =>
                                handleModuleChange(index, updatedModule)
                              }
                              onDeleteModule={() => handleDeleteModule(index)}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Typography
                      level="h4"
                      sx={{
                        textAlign: "center",
                        marginTop: 3,
                      }}
                    >
                      Commencez à créer vos modules en cliquant sur le bouton{" "}
                      <b>+</b>
                    </Typography>
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          {/* Boutons Ajouter module / Sauvegarder */}
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              right: 0,
              width: "10%",
              height: "60px",
              backgroundColor: "#E0E0E0",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px 0 0 0",
            }}
          >
            <Tooltip title="Ajouter module">
              <IconButton sx={{ marginRight: 2 }} onClick={addModule}>
                <SAddModule sx={{ color: "orange" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Sauvegarder">
              <IconButton onClick={handleSaveSession}>
                <SSave sx={{ color: "green" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ) : selectedTemplate !== undefined && selectedSession === undefined ? (
        <Typography sx={{ textAlign: "center", marginTop: 2 }}>
          Aucun modèle pour cette formation
        </Typography>
      ) : (
        <Typography level="h4" sx={{ textAlign: "center", marginTop: "20px" }}>
          Sélectionnez un modèle pour charger la session correspondante.
        </Typography>
      )}
    </Box>
  );
}
