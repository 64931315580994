import React, { Dispatch, SetStateAction } from "react";
import { Box, Grid, Typography, Input, Button, Select, Option } from "@mui/joy";
import { SClose } from "../../../../styles/StyledMUI";
import useAuth from "../../../../contexts/controllers/useAuth";
import { LoginForm } from "../../../../api/auth/Auth.typed";

type UserInviteComponentProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function UserInviteComponent({
  setIsOpen,
}: UserInviteComponentProps) {
  const [email, setEmail] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [role, setRole] = React.useState<number>(1);
  const { inviteRequest } = useAuth();

  const handleInvite = async () => {
    const form: LoginForm = {
      Email: email,
      Name: name,
      Role: role,
    };

    inviteRequest(form);
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 400,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "calc(90% - 50px)",
          overflow: "auto",
          borderRadius: "10px",
          padding: "1rem",
          zIndex: "200",
          backgroundColor: "white",
          textAlign: "start",
        }}
      >
        <Grid container>
          <Grid xs={12} justifyContent='flex-end'>
            <SClose onClick={() => setIsOpen(false)} />
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ marginBottom: "20px" }}>
              Veuillez entrer les informations suivantes pour inviter un
              utilisateur :
            </Typography>
            <Input
              placeholder="Email"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              value={email}
            />
            <Input
              placeholder="Prénom"
              sx={{ width: "100%", marginBottom: "20px" }}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <Select
              placeholder="Rôle"
              value={role}
              onChange={(e, newValue) => setRole(newValue as number)}
              sx={{ width: "100%", marginBottom: "20px" }}
            >
              <Option value={1}>Administrateur</Option>
              <Option value={2}>Moniteur</Option>
            </Select>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="solid"
                sx={{ margin: "10px", width: "50%", height: "40px" }}
                onClick={handleInvite}
                disabled={email === "" || name === ""}
              >
                Soumettre
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
