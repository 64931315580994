import { Box, Typography } from "@mui/joy";

export default function TrainingEmptyItem() {
  return (
    <Box
      component="div"
      sx={{ display: "flex", justifyContent: "center", py: 2 }}
    >
      <Typography level="h4" color="neutral">
        Aucune formation en cours
      </Typography>
    </Box>
  );
}
