import React from "react";
import { Box, Typography, IconButton, Modal, ModalDialog } from "@mui/joy";
import { Session } from "../../../api/zekay/Zekay.typed";
import SessionsForm from "./SessionsForm";
import { SSchool, SEdit } from "../../../styles/StyledMUI";

type SessionItemProps = {
  session: Session;
  isLast: boolean;
  onSessionSave: () => void;
};

export default function SessionItem(props: SessionItemProps) {
  const [openForm, setOpenForm] = React.useState(false);

  const styles = {
    Typography: {
      color: "white",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSaveForm = (updatedSession: Session) => {
    props.onSessionSave(); // Refresh customer after save
    handleCloseForm();
  };

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "8% 20% 25% 25% 10%",
          alignItems: "center",
          gap: 1,
          padding: 1,
          backgroundColor: "#171717",
        }}
      >
        <Box>
          <SSchool sx={{ cursor: "none" }} />
        </Box>
        <Box>
          <Typography
            sx={styles.Typography}
          >{`Séance ${props.session.Number}`}</Typography>
        </Box>
        <Box>
          <Typography sx={styles.Typography}>{props.session.Date}</Typography>
        </Box>
        <Box>
          {props.session.Instructor ? (
            <Typography
              sx={styles.Typography}
            >{`avec ${props.session.Instructor}`}</Typography>
          ) : (
            <Typography sx={{ ...styles.Typography, fontStyle: "italic" }}>
              {props.isLast ? "A remplir" : "Non rempli"}
            </Typography>
          )}
        </Box>
        {props.isLast && (
          <Box>
            <IconButton onClick={handleOpenForm}>
              <SEdit />
            </IconButton>
          </Box>
        )}
      </Box>

      <Modal open={openForm} onClose={handleCloseForm}>
        <ModalDialog aria-labelledby="session-dialog-title" sx={{ width: 500 }}>
          <Typography id="session-dialog-title" level="h4">
            {`Session ${props.session.Number}`}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <SessionsForm
              open={openForm}
              session={props.session}
              onClose={handleCloseForm}
              onSave={handleSaveForm}
            />
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}
