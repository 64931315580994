import React from "react";
import { Button, Input, Box, Typography, CircularProgress } from "@mui/joy";
import Zekay from "../../styles/images/zekay_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginForm } from "../../api/auth/Auth.typed";
import { useAlert } from "../../hooks/useAlert";
import useAuth from "../../contexts/controllers/useAuth";

export default function CreateInvitedUserPage() {
  const [password1, setPassword1] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { verifyToken, createInvitedUser } = useAuth();
  const location = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const token = new URLSearchParams(location.search).get("token");
  const email = new URLSearchParams(location.search).get("email");
  const name = new URLSearchParams(location.search).get("name");
  const role = new URLSearchParams(location.search).get("role");

  const handleInvite = () => {
    if (password1.length >= 8) {
      if (token && email && name && role) {
        if (password1 === password2) {
          const h: LoginForm = {
            Email: email,
            Name: name,
            Role: Number(role),
            Token: token,
            Password: password2,
          };

          setIsLoading(true);
          createInvitedUser(h)
            .then(() => navigate("/login"))
            .catch(() => {
              showAlert("Une erreur est survenue", "danger");
            })
            .finally(() => setIsLoading(false));
        } else {
          showAlert("Les mots de passe ne sont pas identiques", "danger");
        }
      } else {
        showAlert(
          "Des informations sont manquantes, veuillez ré-ouvrir le lien envoyé par mail",
          "danger"
        );
      }
    } else {
      showAlert(
        "La longueur du mot de passe est inférieure à 8 caractères",
        "danger"
      );
    }
  };

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");

    if (token) {
      const h: LoginForm = {
        Token: token,
      };

      verifyToken(h).then((r) => setIsValid(!!r));
    } else {
      setIsValid(false);
    }
  }, [location.search]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          textAlign: "center",
          padding: 3,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <img src={Zekay} alt="Logo" width="200px" />
        </Box>

        {isValid ? (
          <>
            <Typography level="h4" sx={{ marginBottom: 2 }}>
              Bienvenue {name} !
            </Typography>
            <Input
              placeholder="Mot de passe"
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              sx={{ marginY: 1 }}
            />
            <Input
              placeholder="Confirmez le mot de passe"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              sx={{ marginY: 1 }}
            />
            <Button
              variant="solid"
              color="primary"
              sx={{ marginTop: 2, width: "100%" }}
              onClick={handleInvite}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size="sm" /> : "Soumettre"}
            </Button>
          </>
        ) : (
          <>
            <Typography level="h4" sx={{ marginBottom: 2 }}>
              Désolé :(
            </Typography>
            <Typography level="h4">
              Cette page a expiré ou n'est plus valide.
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
}
