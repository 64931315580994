import React from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Input,
  Button,
  Divider,
  IconButton,
} from "@mui/joy";
import { WorkerLog } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import { SearchOutlined } from "@mui/icons-material";

const WorkerLogsComponent: React.FC = () => {
  const [logs, setLogs] = React.useState<WorkerLog[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchId, setSearchId] = React.useState<string>("");
  const { getWorkerLogsByUserId, getWorkerLogsByStatus } = useZekay();

  // Recherche par ID
  const handleSearch = () => {
    setIsLoading(true);
    getWorkerLogsByUserId(searchId)
      .then((l) => setLogs(l))
      .finally(() => setIsLoading(false));
  };

  // Recherche des logs avec un certain status
  const handleGetStatusLogs = (status: string) => {
    setIsLoading(true);
    getWorkerLogsByStatus(status)
      .then((l) => setLogs(l))
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Input
          placeholder="Rechercher par ID"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          sx={{ flex: 1, maxWidth: "250px" }}
        />
        <IconButton onClick={handleSearch} disabled={!searchId}>
          <SearchOutlined />
        </IconButton>
        <Divider orientation="vertical" sx={{ marginX: 1 }} />
        <Button
          color="danger"
          onClick={() => handleGetStatusLogs("error")}
          sx={{ marginX: 1 }}
        >
          Error Log
        </Button>
        <Divider orientation="vertical" sx={{ marginX: 1 }} />
        <Button
          color="primary"
          onClick={() => handleGetStatusLogs("update")}
          sx={{ marginX: 1 }}
        >
          Update Log
        </Button>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "80vh", // Ajuste cette valeur selon tes besoins
            overflowY: "auto", // Permet le défilement vertical
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "8px",
            padding: 2,
          }}
        >
          <List>
            {logs?.map((log) => (
              <ListItem
                key={log.ID}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingY: 1,
                  borderBottom: "1px solid",
                  borderColor: "divider",
                }}
              >
                <Typography level="h4" sx={{ flex: 1, marginRight: 2 }}>
                  {new Date(log.Date).toLocaleString()}
                </Typography>
                <Typography level="h4" sx={{ flex: 1 }}>
                  {log.Status}
                </Typography>
                <Typography level="h4" sx={{ flex: 3 }}>
                  {log.Action}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default WorkerLogsComponent;
