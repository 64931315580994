import { Dispatch, SetStateAction } from "react";
import { Box, IconButton, Input } from "@mui/joy";
import { SNotes, SSave } from "../../styles/StyledMUI";

interface CommentsComponentProps {
  comments: string | undefined;
  setComments: Dispatch<SetStateAction<string | undefined>>;
  handleUpdate: () => void;
  isOpenComments: boolean;
  setIsOpenComments: Dispatch<SetStateAction<boolean>>;
}

export default function CommentsComponent(props: CommentsComponentProps) {
  return (
    <tr>
      <td colSpan={12} style={{ border: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          <SNotes />
          <Input
            placeholder="Ajouter un commentaire"
            value={props.comments || ""}
            onChange={(e) => props.setComments(e.target.value)}
            sx={{ width: "100%", fontSize: "15px", marginLeft: "10px" }}
            endDecorator={
              <IconButton onClick={props.handleUpdate}>
                <SSave sx={{ color: "green" }} />
              </IconButton>
            }
          />
        </Box>
      </td>
    </tr>
  );
}
