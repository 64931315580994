import FileSaver from "file-saver";
import Resizer from "react-image-file-resizer";

export const resizeFile = (
  file: any
): Promise<string | Blob | File | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      512,
      512,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export const convertBase64 = (file: any): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getMimeType = (base64: string) => {
  return base64.split(/[:;]+/)[1];
};

export const getExtension = (mimeType: string) => {
  return mimeType.split("/")[1];
};

export const saveAs = (data: any, fileName: string) => {
  // convert base64 to raw binary data held in a string
  var byteString = atob(data.split(",")[1]);

  // separate out the mime component
  var mimeString = data.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ab], {
    type: mimeString,
  });
  FileSaver.saveAs(blob, fileName + "." + getExtension(mimeString));
};

export const saveAsCSV = (data: string, fileName: string) => {
  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(data.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < data.length; i++) {
    ia[i] = data.charCodeAt(i);
  }

  var blob = new Blob([ab], {
    type: "text/plain;charset=utf-8",
  });
  FileSaver.saveAs(blob, fileName + ".csv");
};

export const base64ToBlob = (base64Data: string): Blob => {
  const base64Index = base64Data.indexOf("base64,") + "base64,".length;
  const base64 = base64Data.substring(base64Index);

  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: "application/pdf" });
};
