import React, { ChangeEvent } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Divider,
  Select,
  Option,
  Input,
} from "@mui/joy";
import { Training, TrainingTemplate } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import TrainingItem from "./TrainingItem";
import TrainingEmptyList from "./trainingEmptyList";

interface TrainingsListProps {
  status: number;
}

const TrainingsList: React.FC<TrainingsListProps> = ({ status }) => {
  const [qualifications, setQualifications] =
    React.useState<TrainingTemplate[]>();
  const [creditId, setCreditId] = React.useState<string>("");
  const [trainings, setTrainings] = React.useState<Training[]>();
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getTrainingsTemplates, getTrainingsByStatusCreditId } = useZekay();

  React.useEffect(() => {
    getTrainingsTemplates().then((templates) => setQualifications(templates));
  }, []);

  React.useEffect(() => {
    if (creditId) {
      setIsLoading(true);
      getTrainingsByStatusCreditId(creditId, status)
        .then((ts) => setTrainings(ts || []))
        .finally(() => setIsLoading(false));
    }
  }, [creditId, status]);

  const handleChangeQualification = (newValue: string | null) => {
    setCreditId(newValue || "");
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const filteredTrainings = trainings?.filter((t) =>
    t.LastName?.toLowerCase().startsWith(searchInput.toLowerCase())
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Header avec sélection et recherche */}
      <Box sx={{ flexShrink: 0, paddingBottom: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* Select et Total */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Select
              placeholder="Formations"
              value={creditId}
              onChange={(e, newValue) => handleChangeQualification(newValue)}
              sx={{ width: "400px" }}
            >
              {qualifications?.map((q) => (
                <Option key={q.ID} value={q.CreditID}>
                  {q.Agency + " - " + q.Qualification}
                </Option>
              ))}
            </Select>
            <Typography level="h4" sx={{ color: "#FCCB06" }}>
              {trainings && trainings.length > 0 && !isLoading
                ? `Total : ${trainings.length}`
                : null}
            </Typography>
          </Box>

          {/* Input de recherche */}
          {creditId && !isLoading && (
            <Input
              placeholder="Rechercher"
              value={searchInput}
              onChange={handleSearch}
              sx={{ width: "300px" }}
            />
          )}
        </Box>
        <Divider sx={{ marginTop: "10px" }} />
      </Box>

      {/* Table scrollable */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  {[
                    "Prénom",
                    "Nom",
                    "Organisation",
                    "Qualification",
                    "Commentaires",
                    "Sessions théoriques",
                    "Documents",
                    "Résultats QCM",
                    "Profil",
                    "Mail",
                    "Zuurit",
                    "Annuler",
                    "Info",
                  ].map((header, index) => (
                    <th
                      key={index}
                      style={{
                        textAlign: "left",
                        color: "#fff", // Texte en blanc pour contraste
                        width: `${100 / 13}%`, // Répartir les colonnes uniformément
                        position: "sticky", // Reste visible
                        top: 0,
                        zIndex: 2,
                        wordWrap: "break-word", // Retourne à la ligne
                        wordBreak: "break-word", // Gère les mots longs
                        whiteSpace: "normal", // Autorise le retour à la ligne
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography level="h4">{header}</Typography>
                    </th>
                  ))}
                </tr>
              </thead>
            </table>
            <Box
              sx={{
                overflowY: "auto",
                height: "100%",
              }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <tbody>
                  {filteredTrainings && filteredTrainings.length > 0 ? (
                    filteredTrainings.map((training) => (
                      <TrainingItem
                        key={training.CustomerID}
                        training={training}
                        setTrainings={setTrainings}
                        status={status}
                      />
                    ))
                  ) : (
                    <TrainingEmptyList />
                  )}
                </tbody>
              </table>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TrainingsList;
