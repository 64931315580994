import { Box, Typography } from "@mui/joy";

export default function SessionEmptyItem() {
  return (
    <Box
      component="div"
      sx={{ display: "flex", justifyContent: "center", py: 2 }}
    >
      <Typography level="h4" color="neutral">
        Aucun suivi en cours
      </Typography>
    </Box>
  );
}
