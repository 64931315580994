import React from "react";
import {
  Box,
  Typography,
  Grid,
  Chip,
  Divider,
  CircularProgress,
  ChipDelete,
  IconButton,
} from "@mui/joy";
import useZekay from "../../../contexts/controllers/useZekay";
import { useAlert } from "../../../hooks/useAlert";
import { SAddCriteria, SAddOption, SSave } from "../../../styles/StyledMUI";
import CustomMenu from "../../nav/CustomMenu";

interface GroupsMapping {
  [key: string]: string[];
}

const GroupsComponent: React.FC = () => {
  const {
    getGoogleLabels,
    getQualificationsList,
    getNewsletterMapping,
    updateNewsletterMapping,
  } = useZekay();
  const [qualificationsList, setQualificationsList] = React.useState<
    string[] | undefined
  >([]);
  const [newsletterMapping, setNewsletterMapping] =
    React.useState<GroupsMapping>({});
  const [availableLabels, setAvailableLabels] = React.useState<
    string[] | undefined
  >([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { showAlert } = useAlert();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const labels = await getGoogleLabels();
        const qualifications = await getQualificationsList();
        const mapping = await getNewsletterMapping();

        setQualificationsList(qualifications);
        setNewsletterMapping(mapping || {});

        const mappedLabels = Object.keys(mapping || {});
        const nonMappedLabels = labels?.filter(
          (label) => !mappedLabels.includes(label)
        );
        setAvailableLabels(nonMappedLabels);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleMappingChange = (label: string, qualification: string) => {
    setNewsletterMapping((prevMapping) => {
      if (prevMapping[label] && prevMapping[label].includes(qualification)) {
        return prevMapping;
      }
      return {
        ...prevMapping,
        [label]: prevMapping[label]
          ? [...prevMapping[label], qualification]
          : [qualification],
      };
    });
  };

  const handleRemoveQualification = (label: string, qualification: string) => {
    setNewsletterMapping((prevMapping) => ({
      ...prevMapping,
      [label]: prevMapping[label].filter((q) => q !== qualification),
    }));
  };

  const handleSave = () => {
    if (newsletterMapping) {
      updateNewsletterMapping(newsletterMapping).then(() =>
        showAlert("Configuration mise à jour avec succès", "success")
      );
    }
  };

  const getAvailableQualifications = (label: string) => {
    const mappedQualifications = newsletterMapping[label] || [];
    return qualificationsList?.filter((q) => !mappedQualifications.includes(q));
  };

  const handleAddLabel = (label: string) => {
    setNewsletterMapping((prevMapping) => ({
      ...prevMapping,
      [label]: [],
    }));
    setAvailableLabels((prevAvailable) =>
      prevAvailable?.filter((l) => l !== label)
    );
  };

  return (
    <Box sx={{ width: "100%", padding: 2, height: "100%" }}>
      <Typography level="h4" mb={2} sx={{ color: "#FCCB06" }}>
        Groupes
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ maxWidth: "80vw", overflowY: "auto" }}>
        {Object.keys(newsletterMapping).map((label, index) => (
          <Grid xs={12} key={index} marginLeft={4}>
            <Typography level="h4">{"Groupe " + label}</Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              gap={1}
              mb={2}
            >
              {newsletterMapping[label] &&
                newsletterMapping[label].map((qualification, idx) => (
                  <Chip
                    key={idx}
                    size="sm"
                    variant="soft"
                    endDecorator={
                      <ChipDelete
                        onDelete={() =>
                          handleRemoveQualification(label, qualification)
                        }
                      />
                    }
                  >
                    {qualification}
                  </Chip>
                ))}

              <CustomMenu
                items={getAvailableQualifications(label) || []}
                onItemClick={(qualification) =>
                  handleMappingChange(label, qualification)
                }
                triggerLabel={<SAddOption />}
              />
            </Box>
          </Grid>
        ))}
      </Box>

      <Box sx={{ margin: 2 }}>
        <CustomMenu
          items={availableLabels || []}
          onItemClick={handleAddLabel}
          triggerLabel={<SAddCriteria />}
        />
      </Box>

      <Box sx={{ margin: 3 }}>
        <IconButton onClick={handleSave}>
          <SSave />
        </IconButton>
      </Box>
    </Box>
  );
};

export default GroupsComponent;
