import React, { Dispatch, SetStateAction } from "react";
import { Box, CircularProgress, IconButton, Tooltip, Avatar } from "@mui/joy";
import { PhotoCamera } from "@mui/icons-material";
import { resizeFile } from "../../../utils/fileSaver";

const empty = require("../../../styles/images/noimage.png");

type PhotoComponentProps = {
  photo: string | Blob | File | ProgressEvent<FileReader> | undefined;
  setPhoto: Dispatch<
    SetStateAction<string | Blob | File | ProgressEvent<FileReader> | undefined>
  >;
};

export default function PhotoComponent(props: PhotoComponentProps) {
  const [isLoadingP, setIsLoadingP] = React.useState<boolean>(false);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    hiddenFileInput.current?.click();
  };

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingP(true);
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await resizeFile(file);
      props.setPhoto(base64);
    }
    setIsLoadingP(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
        textAlign: "center",
        position: "relative",
      }}
    >
      {!isLoadingP ? (
        <>
          <Box
            sx={{
              position: "relative",
              width: 120,
              height: 120,
              margin: "auto",
              borderRadius: "50%",
              overflow: "hidden",
              border: "2px solid #ccc",
              backgroundColor: "#f9f9f9",
            }}
          >
            <Avatar
              src={props.photo !== undefined ? (props.photo as string) : empty}
              alt="photo"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
            <input
              ref={hiddenFileInput}
              style={{ display: "none" }}
              type="file"
              onChange={uploadImage}
              accept="image/*"
            />
          </Box>
          <Tooltip title="Ajouter photo">
            <IconButton
              color="primary"
              size="lg"
              sx={{
                position: "absolute",
                bottom: 0,
                right: "45%",
                zIndex: 10,
              }}
              onClick={handleButtonClick}
            >
              <PhotoCamera />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <CircularProgress
          sx={{
            margin: "auto",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      )}
    </Box>
  );
}
