import React from "react";
import {
  ModalContextType,
  UseModalShowReturnType,
  ConfirmationModalContext,
} from "../contexts/ConfirmationContext";

const useConfirmationModal = (): ModalContextType =>
  React.useContext(ConfirmationModalContext);

const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = React.useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

export { useModalShow, useConfirmationModal };
