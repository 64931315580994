import { Dispatch, SetStateAction } from "react";
import { Box, IconButton, Typography } from "@mui/joy";
import { Training } from "../../api/zekay/Zekay.typed";
import useAuth from "../../contexts/controllers/useAuth";
import { SDate, SReset } from "../../styles/StyledMUI";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

interface DatesComponentProps {
  training: Training;
  theory: Dayjs | null;
  rifap: Dayjs | null;
  setTheory: Dispatch<SetStateAction<Dayjs | null>>;
  setRifap: Dispatch<SetStateAction<Dayjs | null>>;
  updateTraining: (training: Training) => Promise<void>;
  getTrainingsByCreditId: (creditId: string) => Promise<Training[] | undefined>;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  isOpenDates: boolean;
}

export default function DatesComponent({
  training,
  theory,
  rifap,
  setTheory,
  setRifap,
  updateTraining,
  getTrainingsByCreditId,
  setTrainings,
  isOpenDates,
}: DatesComponentProps) {
  const { user } = useAuth();
  const maxDate = dayjs().format("YYYY-MM-DD");

  const handleDateChange = (date: string, type: "theory" | "rifap") => {
    const utcDate = dayjs(date).utc();

    if (type === "theory") setTheory(utcDate);
    else setRifap(utcDate);

    const updatedTraining: Training = {
      ...training,
      Theory:
        type === "theory" ? utcDate.toISOString() : theory?.toISOString() || "",
      RifapDate:
        type === "rifap" ? utcDate.toISOString() : rifap?.toISOString() || "",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(updatedTraining)
      .then(() => getTrainingsByCreditId(String(training.CreditId)))
      .then((ts) => setTrainings(ts));
  };

  const handleResetDate = (type: "theory" | "rifap") => {
    if (type === "theory") setTheory(null);
    else setRifap(null);

    const updatedTraining: Training = {
      ...training,
      Theory: type === "theory" ? "" : theory?.toISOString() || "",
      RifapDate: type === "rifap" ? "" : rifap?.toISOString() || "",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(updatedTraining)
      .then(() => getTrainingsByCreditId(String(training.CreditId)))
      .then((ts) => setTrainings(ts));
  };

  return (
    <tr>
      <td colSpan={12} style={{ border: "none" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: 1,
            marginBottom: 1,
          }}
        >
          <SDate />
          {training.NeedsTheory && (
            <Box sx={{ marginLeft: "10px" }}>
              <Typography level="h4" mb={2} color="neutral">
                Cours théorique
              </Typography>
              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <Box
                  component="input"
                  type="date"
                  value={dayjs(theory)
                    .tz(dayjs.tz.guess())
                    .format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e.target.value, "theory")}
                  max={maxDate}
                  sx={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: "neutral.outlinedBorder",
                  }}
                />
                {theory && (
                  <IconButton onClick={() => handleResetDate("theory")}>
                    <SReset />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
          {training.NeedsRifap && (
            <Box>
              <Typography level="h4" mb={2} color="neutral">
                RIFAP
              </Typography>
              <Box display="flex" alignItems="center" gap={1} mt={1}>
                <Box
                  component="input"
                  type="date"
                  value={dayjs(theory)
                    .tz(dayjs.tz.guess())
                    .format("YYYY-MM-DD")}
                  onChange={(e) => handleDateChange(e.target.value, "rifap")}
                  max={maxDate}
                  sx={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid",
                    borderColor: "neutral.outlinedBorder",
                  }}
                />
                {rifap && (
                  <IconButton onClick={() => handleResetDate("rifap")}>
                    <SReset />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </td>
    </tr>
  );
}
