import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/joy";
import {
  SAddDocument,
  SAnalytics,
  SDelete,
  SMissingFile,
  SView,
} from "../../../styles/StyledMUI";
import { Training } from "../../../api/zekay/Zekay.typed";
import TrainingSummaryModal from "../../customers/trainings/TrainingSummaryModal";

interface SheetTypeComponentProps {
  uploadFile: (event: any, type: string) => Promise<void>;
  deleteFile: (fileType: string) => Promise<void>;
  handleView: (type: string) => void;
  document: string | undefined;
  documentType: string;
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings: RefreshTrainingFunction;
}

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

export default function SheetTypeComponent({
  uploadFile,
  deleteFile,
  handleView,
  document,
  documentType,
  training,
  setTrainings,
  refreshTrainings,
}: SheetTypeComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = React.useState<boolean>(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    uploadFile(e, documentType).then(() => setIsLoading(false));
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "8px",
          padding: 2,
          textAlign: "center",
          width: "100%",
          backgroundColor: "#171717",
        }}
      >
        <Typography level="h4" mb={1} color="neutral">
          Fiche Suivi
        </Typography>

        {!isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Box display="flex" alignItems="center" gap={2}>
              {training.Sessions !== undefined && (
                <Tooltip title="Voir synthèse">
                  <IconButton onClick={() => setSummaryOpen(true)}>
                    <SAnalytics sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              )}
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleUpload}
                  accept="application/pdf"
                />
                <Tooltip title="Ajouter document">
                  <IconButton component="span">
                    <SAddDocument sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </label>
            </Box>

            {document ? (
              <Box display="flex" alignItems="center" gap={2}>
                <Tooltip title="Voir document">
                  <IconButton onClick={() => handleView(documentType)}>
                    <SView sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer document">
                  <IconButton
                    onClick={() => deleteFile(documentType)}
                    color="danger"
                  >
                    <SDelete sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <SMissingFile sx={{ color: "orange" }} />
            )}
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress size="sm" />
          </Box>
        )}
      </Box>

      <TrainingSummaryModal
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        customerId={training.CustomerID}
        creditId={training.CreditId}
        setTrainings={setTrainings}
        refreshTrainings={refreshTrainings}
      />
    </>
  );
}
