import { Tooltip, Typography } from "@mui/joy";
import { Training } from "../../api/zekay/Zekay.typed";
import { formatDate } from "../../utils/dateTime";
import { SInfo } from "../../styles/StyledMUI";

interface DetailsTooltipProps {
  training: Training;
}

export default function DetailsTooltip(props: DetailsTooltipProps) {
  return (
    <Tooltip
      sx={{ backgroundColor: "#171717" }}
      title={
        <>
          <Typography level="h4">
            Créée à :{" "}
            {props.training.CreatedAt
              ? formatDate(props.training.CreatedAt)
              : "-"}
          </Typography>
          <Typography level="h4">
            Mise à jour à :{" "}
            {props.training.UpdatedAt
              ? formatDate(props.training.UpdatedAt)
              : "-"}
          </Typography>
          <Typography level="h4">
            Mise à jour par : {props.training.UpdatedBy || "-"}
          </Typography>
        </>
      }
    >
      <SInfo sx={{ color: "darkgrey" }} />
    </Tooltip>
  );
}
