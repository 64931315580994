import React from "react";
import { Box, Button, Grid, Input, Typography, CircularProgress } from "@mui/joy";
import Zekay from "../../styles/images/zekay_logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { LoginForm } from "../../api/auth/Auth.typed";
import { useAlert } from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";

export default function ResetRequestPage() {
  const [email, setEmail] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { resetRequest } = React.useContext(AuthContext);
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const handleReset = async () => {
    if (email) {
      setIsLoading(true);
      try {
        const requestData: LoginForm = { Email: email };
        await resetRequest(requestData);
        showAlert("Email de réinitialisation envoyé !", "success");
        navigate("/");
      } catch (err) {
        if (err instanceof Error) {
          showAlert(err.message, "danger");
        }
      }
      setIsLoading(false);
    } else {
      showAlert("Veuillez entrer une adresse e-mail valide.", "warning");
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "50vh",
        overflowX: "hidden",
        margin: 0,
      }}
    >
      {/* Logo */}
      <Grid xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Zekay} width="200px" alt="Logo" />
        </Box>
      </Grid>

      {/* Formulaire */}
      <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%", maxWidth: 400, textAlign: "center" }}>
          <Typography level="h4" sx={{ marginBottom: 2 }}>
            Réinitialisez votre mot de passe
          </Typography>
          <Input
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            sx={{ marginY: 1 }}
            onKeyDown={(e) => e.key === "Enter" && handleReset()}
          />
          <Button
            variant="solid"
            color="primary"
            sx={{ marginTop: 2, width: "100%" }}
            onClick={handleReset}
            disabled={isLoading || !email}
          >
            {isLoading ? <CircularProgress size="sm" /> : "Envoyer"}
          </Button>
          <Button
            variant="plain"
            size="sm"
            onClick={() => navigate("/")}
            sx={{ fontSize: "0.8rem", marginY: 1 }}
          >
            Retour à la connexion
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}