import { Box, Grid, Input, Typography } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";

type AddressComponentProps = {
  street: string | undefined;
  postalCode: string | undefined;
  city: string | undefined;
  setStreet: Dispatch<SetStateAction<string | undefined>>;
  setPostalCode: Dispatch<SetStateAction<string | undefined>>;
  setCity: Dispatch<SetStateAction<string | undefined>>;
};

export default function AddressComponent(props: AddressComponentProps) {
  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    props.setPostalCode(inputValue);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box sx={{ margin: "10px" }}>
            <Typography level="h4" sx={{ marginBottom: "4px", color: "black" }}>
              Numéro et rue
            </Typography>
            <Input
              placeholder="Numéro et rue"
              value={props.street || ""}
              onChange={(e) => props.setStreet(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box sx={{ margin: "10px" }}>
            <Typography level="h4" sx={{ marginBottom: "4px", color: "black" }}>
              Code Postal
            </Typography>
            <Input
              placeholder="Code Postal"
              value={props.postalCode || ""}
              onChange={handlePostalCodeChange}
              type="number"
              sx={{ width: "100%" }}
            />
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box sx={{ margin: "10px" }}>
            <Typography level="h4" sx={{ marginBottom: "4px", color: "black" }}>
              Ville
            </Typography>
            <Input
              placeholder="Ville"
              value={props.city || ""}
              onChange={(e) => props.setCity(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}