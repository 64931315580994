import { ZekayCustomer, Training } from "../../../api/zekay/Zekay.typed";
import TrainingEmptyItem from "./TrainingEmptyItem";
import { Dispatch, SetStateAction } from "react";
import TrainingItem from "./TrainingItem";

type TrainingListProps = {
  customer: ZekayCustomer | undefined;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
  setTrainingsOpen: Dispatch<SetStateAction<boolean>>;
};

export default function TrainingList(props: TrainingListProps) {
  return props.customer?.Trainings !== undefined ? (
    props.customer?.Trainings?.map((t: Training) => {
      return (
        <TrainingItem
          key={t.ID}
          training={t}
          customer={props.customer}
          setCustomerUpdated={props.setCustomerUpdated}
          setTrainingsFormOpen={props.setTrainingsOpen}
        />
      );
    })
  ) : (
    <TrainingEmptyItem />
  );
}
