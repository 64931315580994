import React from "react";
import { Box, Typography, Switch, Divider, IconButton } from "@mui/joy";
import { SSave } from "../../../styles/StyledMUI";
import useZekay from "../../../contexts/controllers/useZekay";
import { SystemInfos, ZekayConfig } from "../../../api/zekay/Zekay.typed";
import { useAlert } from "../../../hooks/useAlert";
import StorageIcon from "@mui/icons-material/Storage";
import { JsonEditor } from "json-edit-react";

export default function GeneralSettingsPage() {
  const { getConfig, updateConfig, getSystemInfos } = useZekay();
  const [config, setConfig] = React.useState<ZekayConfig | null>(null);
  const [infos, setInfos] = React.useState<SystemInfos>();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    getConfig(1).then((conf) => setConfig(conf || null));
  }, []);

  React.useEffect(() => {
    getSystemInfos().then((infos) => setInfos(infos));
  }, []);

  const handleJsonChange = ({
    currentData,
    newValue,
    currentValue,
    name,
    path,
  }: {
    currentData: any;
    newValue: any;
    currentValue: any;
    name: string | number;
    path: (string | number)[];
  }) => {
    return newValue;
  };

  const setData = (updatedJson: any, field: string) => {
    setConfig((prevConfig) => ({
      ...prevConfig!,
      [field]: updatedJson,
    }));
  };

  const handleSave = () => {
    if (config) {
      updateConfig(config).then(() =>
        showAlert("Configuration mise à jour avec succès", "success")
      );
    }
  };

  const handleToggleMailingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (config) {
      const updatedConfig: ZekayConfig = {
        ...config,
        AutomaticMailing: event.target.checked,
      };
      setConfig(updatedConfig);
      updateConfig(updatedConfig).then(() =>
        showAlert("Configuration mise à jour avec succès", "success")
      );
    }
  };

  const handleToggleGroupsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (config) {
      const updatedConfig: ZekayConfig = {
        ...config,
        ManageGroups: event.target.checked,
      };
      setConfig(updatedConfig);
      updateConfig(updatedConfig).then(() =>
        showAlert("Configuration mise à jour avec succès", "success")
      );
    }
  };

  if (!config) {
    return null;
  }

  return (
    <Box sx={{ width: "100%", padding: 2, overflowY: "auto" }}>
      <Typography level="h4" fontWeight="lg" marginBottom={2} sx={{ color: "#FCCB06" }}>
        Paramètres généraux
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Section Mails */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography level="h4">Mails</Typography>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <Typography
            level="h4"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Envoi automatique des mails
            <Switch
              checked={config.AutomaticMailing}
              onChange={handleToggleMailingChange}
            />
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Groups */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography level="h4">Groupes</Typography>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <Typography
            level="h4"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            Mise à jour automatique des groupes Google
            <Switch
              checked={config.ManageGroups}
              onChange={handleToggleGroupsChange}
            />
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Token Editor */}
      <Typography level="h4">Google Token</Typography>
      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        <Box sx={{ maxWidth: "80vw" }}>
          <JsonEditor
            data={config.google_token}
            setData={(newData) => setData(newData, "google_token")}
            onChange={handleJsonChange}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <IconButton onClick={handleSave}>
            <SSave />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Credentials Editor */}
      <Typography level="h4">Google Credentials</Typography>
      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        <Box sx={{ maxWidth: "80vw" }}>
          <JsonEditor
            data={config.google_credentials}
            setData={(newData) => setData(newData, "google_credentials")}
            onChange={handleJsonChange}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <IconButton onClick={handleSave}>
            <SSave />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 3 }} />

      {/* Section Google Spreadsheet Editor */}
      <Typography level="h4">Google Spreadsheet (RGPD)</Typography>
      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        <Box sx={{ maxWidth: "80vw" }}>
          <JsonEditor
            data={config.google_spreadsheet}
            setData={(newData) => setData(newData, "google_spreadsheet")}
            onChange={handleJsonChange}
            rootFontSize={"95%"}
          />
        </Box>
        <Box sx={{ margin: 2 }}>
          <IconButton onClick={handleSave}>
            <SSave />
          </IconButton>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Section Stockage */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography level="h4">Stockage</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 4,
            marginTop: 2,
          }}
        >
          <StorageIcon sx={{ marginRight: 2 }} />
          <Typography level="h4">
            {infos?.UsedSpace.toFixed(2)} Go utilisés sur{" "}
            {infos?.TotalSpace.toFixed(2)} Go disponibles
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
