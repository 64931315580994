import React, { Dispatch, SetStateAction } from "react";
import { Box, Avatar, IconButton, CircularProgress, Tooltip } from "@mui/joy";
import {
  SAddPhoto,
  SDownloadPhoto,
  SPhoto,
} from "../../../../styles/StyledMUI";
import { ZekayCustomer, File } from "../../../../api/zekay/Zekay.typed";
import { resizeFile, saveAs } from "../../../../utils/fileSaver";
import useZekay from "../../../../contexts/controllers/useZekay";

const empty = require("../../../../styles/images/noimage.png");

interface PhotoComponentProps {
  customer: ZekayCustomer | undefined;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
}

export default function PhotoComponent({
  customer,
  setCustomerUpdated,
}: PhotoComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getCustomer, updateFile } = useZekay();

  const uploadImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files[0];
    const base64 = await resizeFile(file);

    const form: File = {
      CustomerID: customer?.Id,
      Type: "photo",
      File: base64,
    };

    await updateFile(form)
      .then(() =>
        setTimeout(
          () =>
            getCustomer(String(customer?.Id)).then((r) =>
              setCustomerUpdated(r)
            ),
          1000
        )
      )
      .finally(() => setIsLoading(false));
  };

  const downloadImage = () => {
    if (customer?.Photo) {
      saveAs(customer.Photo, `${customer.FirstName}_${customer.LastName}`);
    }
  };

  return (
    <Box
      sx={{ position: "relative", display: "flex", justifyContent: "center" }}
    >
      <Box sx={{ position: "relative", width: "200px", height: "200px" }}>
        {isLoading ? (
          <CircularProgress
            size="lg"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        ) : (
          <>
            <Avatar
              src={customer?.Photo || empty}
              alt="Customer photo"
              sx={{ width: "100%", height: "100%" }}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 10,
                display: "flex",
                gap: 1,
              }}
            >
              <label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={uploadImage}
                  style={{ display: "none" }}
                />
                <Tooltip title="Ajouter photo">
                  <IconButton variant="plain" color="primary" component="span">
                    <SAddPhoto />
                  </IconButton>
                </Tooltip>
              </label>
              {customer?.Photo && (
                <Tooltip title="Télécharger photo">
                  <IconButton
                    variant="plain"
                    color="success"
                    onClick={downloadImage}
                  >
                    <SDownloadPhoto />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
