import React from "react";
import {
  ResponsiveContainer,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { Box, FormControl, Select, Option, Typography } from "@mui/joy";
import { useAlert } from "../../../../hooks/useAlert";
import useZekay from "../../../../contexts/controllers/useZekay";
import {
  ExcursionsStats,
  StatDate,
  StatsHandler,
} from "../../../../api/zekay/Zekay.typed";

export default function TopCustomers() {
  const [dates, setDates] = React.useState<StatDate[]>();
  const [year, setYear] = React.useState<string>("");
  const [month, setMonth] = React.useState<string[]>();
  const [monthName, setMonthName] = React.useState<string>("");
  const [monthValue, setMonthValue] = React.useState<number>(0);
  const [excursionsStatsByMonth, setExcursionsStatsByMonth] =
    React.useState<ExcursionsStats>();
  const { showAlert } = useAlert();
  const { getStatDates, getExcursionsStatsByMonth } = useZekay();

  React.useEffect(() => {
    getStatDates()
      .then((dates) => setDates(dates))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "danger");
        }
      });
  }, []);

  React.useEffect(() => {
    if (year && month) {
      const date: StatsHandler = { Year: Number(year), Month: monthValue };
      getExcursionsStatsByMonth(date)
        .then((stat) => setExcursionsStatsByMonth(stat))
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "danger");
          }
        });
    }
  }, [year, monthValue]);

  React.useEffect(() => {
    if (dates) {
      const selectedYear = dates.find((m) => m.Year === Number(year));
      setMonth(selectedYear?.Month);
    }
  }, [year]);

  return (
    <React.Fragment>
      <Typography level="h4" mb={2} sx={{ color: "#FCCB06" }}>
        Top Clients
      </Typography>
      <Box display="flex" gap={2} justifyContent="center" mb={3}>
        <FormControl>
          <Select
            placeholder="Année"
            value={year}
            onChange={(event, newValue) => setYear(newValue as string)}
            sx={{ minWidth: "100px" }}
          >
            {dates?.map((date) => (
              <Option key={date.Year} value={date.Year.toString()}>
                {date.Year}
              </Option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            placeholder="Mois"
            value={monthName}
            onChange={(event, newValue) => {
              setMonthValue(Number(newValue));
              setMonthName(newValue as string);
            }}
            sx={{ minWidth: "100px" }}
          >
            {month?.map((m, index) => (
              <Option key={index} value={(index + 1).toString()}>
                {m}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box padding={3}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={excursionsStatsByMonth?.TopCustomers}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Name" />
            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
            <Tooltip />
            <Bar yAxisId="left" dataKey="Count" fill="primary.main" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
