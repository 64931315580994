import React, { useState, Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  Badge,
  IconButton,
  Tooltip,
  Input,
  Link,
} from "@mui/joy";
import {
  Mail,
  Training,
  ZekayCustomer,
} from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import useAuth from "../../../../contexts/controllers/useAuth";
import CommentsComponent from "../../../common/CommentsComponent";
import ProfileForm from "../../../customers/profiles/ProfileForm";
import DatesComponent from "../../../common/DatesComponent";
import DocumentsComponent from "../../../common/DocumentsComponent";
import dayjs, { Dayjs } from "dayjs";
import DetailsTooltip from "../../../common/DetailsTooltip";
import {
  SDate,
  SDelete,
  SFile,
  SNotes,
  SOpenProfile,
  SRedirect,
  SSave,
  SSendMail,
} from "../../../../styles/StyledMUI";
import { useAlert } from "../../../../hooks/useAlert";

interface TrainingItemProps {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
  readonly?: boolean;
  status: number;
}

const TrainingItem: React.FC<TrainingItemProps> = ({
  training,
  setTrainings,
  status,
}) => {
  const {
    getCustomer,
    updateTraining,
    getTrainingsByStatusCreditId,
    cancelTraining,
    sendMail,
  } = useZekay();
  const { user } = useAuth();
  const { showAlert } = useAlert();

  const [profileFormOpen, setProfileFormOpen] = useState(false);
  const [customer, setCustomer] = useState<ZekayCustomer | undefined>();
  const [comments, setComments] = useState(training.Comments);
  const [testResult, setTestResult] = useState(training.TestResult || "");
  const [theory, setTheory] = useState<Dayjs | null>(
    training.Theory ? dayjs(training.Theory) : null
  );
  const [rifap, setRifap] = useState<Dayjs | null>(
    training.RifapDate ? dayjs(training.RifapDate) : null
  );
  const [isOpenComments, setIsOpenComments] = useState(false);
  const [isOpenDocuments, setIsOpenDocuments] = useState(false);
  const [isOpenDates, setIsOpenDates] = useState(false);

  const handleTestResultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestResult(e.target.value.replace(/\D/g, ""));
  };

  const handleUpdate = async () => {
    const updatedTraining = {
      ...training,
      Comments: comments,
      TestResult: testResult,
      Theory: theory ? theory.toISOString() : "",
      RifapDate: rifap ? rifap.toISOString() : "",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };
    await updateTraining(updatedTraining);
    const updatedTrainings = await getTrainingsByStatusCreditId(
      String(training.CreditId),
      status
    );
    setTrainings(updatedTrainings);
  };

  const handleSendMail = async () => {
    const customer = await getCustomer(String(training?.CustomerID));
    if (customer?.MailAddress) {
      const mailData: Mail = {
        ZuuritID: customer.Id,
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        MailAddress: customer.MailAddress,
        CreditID: training?.CreditId,
      };
      await sendMail(mailData);
      showAlert("Mail envoyé !", "success");
    }
  };

  const handleDelete = async () => {
    const cancelledTraining = { ...training, Status: "Annulé" };
    await cancelTraining(cancelledTraining);
    const updatedTrainings = await getTrainingsByStatusCreditId(
      String(training.CreditId),
      status
    );
    setTrainings(updatedTrainings);
  };

  const handleClickOpenProfile = async () => {
    const fetchedCustomer = await getCustomer(String(training.CustomerID));
    setCustomer(fetchedCustomer);
    setProfileFormOpen(true);
  };

  return (
    <>
      <tr key={training.CustomerID}>
        {[
          {
            content: <Typography level="h4">{training.FirstName}</Typography>,
          },
          {
            content: <Typography level="h4">{training.LastName}</Typography>,
          },
          {
            content: <Typography level="h4">{training.Agency}</Typography>,
          },
          {
            content: (
              <Typography level="h4">{training.Qualification}</Typography>
            ),
          },
          {
            content: (
              <Badge badgeContent={comments ? 1 : 0} size="sm" color="neutral">
                <IconButton onClick={() => setIsOpenComments(!isOpenComments)}>
                  <SNotes />
                </IconButton>
              </Badge>
            ),
          },
          {
            content: training.NeedsTheory ? (
              <IconButton onClick={() => setIsOpenDates(!isOpenDates)}>
                <SDate />
              </IconButton>
            ) : (
              <Typography level="h4">N/A</Typography>
            ),
          },
          {
            content: (
              <IconButton onClick={() => setIsOpenDocuments(!isOpenDocuments)}>
                <SFile />
              </IconButton>
            ),
          },
          {
            content: (
              <Box display="flex">
                <Input
                  value={testResult}
                  onChange={handleTestResultChange}
                  endDecorator="%"
                  sx={{ width: "80px" }}
                  disabled={status !== 1}
                />
                {status === 1 && (
                  <IconButton onClick={handleUpdate}>
                    <SSave sx={{ color: "green" }} />
                  </IconButton>
                )}
              </Box>
            ),
          },
          {
            content: (
              <IconButton onClick={handleClickOpenProfile}>
                <SOpenProfile />
              </IconButton>
            ),
          },
          {
            content:
              status === 1 ? (
                <IconButton onClick={handleSendMail}>
                  <SSendMail />
                </IconButton>
              ) : (
                <Typography level="h4">N/A</Typography>
              ),
          },
          {
            content: (
              <Link
                href={`https://app.zuurit.com/customers/${training.CustomerID}`}
                target="_blank"
              >
                <SRedirect />
              </Link>
            ),
          },
          {
            content: status === 1 && (
              <Tooltip title="Annuler">
                <IconButton onClick={handleDelete}>
                  <SDelete sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            ),
          },
          { content: <DetailsTooltip training={training} />, width: "9%" },
        ].map((cell, index) => (
          <td
            key={index}
            style={{
              textAlign: "left",
              paddingTop: "10px",
              paddingBottom: "10px",
              width: `${100 / 13}%`,
              wordWrap: "break-word", // Retourne à la ligne
              wordBreak: "break-word", // Coupe les mots longs
              whiteSpace: "normal", // Autorise le retour à la ligne
            }}
          >
            {cell.content}
          </td>
        ))}
      </tr>

      {isOpenComments && (
        <CommentsComponent
          comments={comments}
          setComments={setComments}
          handleUpdate={handleUpdate}
          isOpenComments={isOpenComments}
          setIsOpenComments={setIsOpenComments}
        />
      )}
      {isOpenDates && (
        <DatesComponent
          training={training}
          theory={theory}
          rifap={rifap}
          setTheory={setTheory}
          setRifap={setRifap}
          updateTraining={updateTraining}
          getTrainingsByCreditId={() =>
            getTrainingsByStatusCreditId(String(training.CreditId), status)
          }
          setTrainings={setTrainings}
          isOpenDates={isOpenDates}
        />
      )}
      {isOpenDocuments && (
        <DocumentsComponent
          training={training}
          setTrainings={setTrainings}
          refreshTrainings={() =>
            getTrainingsByStatusCreditId(String(training.CreditId), status)
          }
          isOpenDocuments={isOpenDocuments}
        />
      )}
      {profileFormOpen && (
        <tr>
          <td colSpan={12} style={{ border: "none" }}>
            <ProfileForm
              customer={customer}
              setProfileFormOpen={setProfileFormOpen}
              setCustomerUpdated={setCustomer}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default TrainingItem;
