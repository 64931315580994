import React from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Select,
  Option,
  Typography,
  Input,
  FormControl,
  FormLabel,
} from "@mui/joy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { TrainingTemplate, Card } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";
import AddIcon from "@mui/icons-material/Add";
import { SDelete, SSave } from "../../../../styles/StyledMUI";

export default function TrainingTemplateEditor() {
  const [templates, setTemplates] = React.useState<TrainingTemplate[] | null>(
    null
  );
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TrainingTemplate | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [newCardName, setNewCardName] = React.useState<string>("");

  const { getTrainingsTemplates, updateTrainingTemplate } = useZekay();

  React.useEffect(() => {
    setIsLoading(true);
    getTrainingsTemplates()
      .then((t) => setTemplates(t || []))
      .finally(() => setIsLoading(false));
  }, [getTrainingsTemplates]);

  const handleChangeTemplate = (newValue: string | null) => {
    const template = templates?.find((t) => t.CreditID.toString() === newValue);
    setSelectedTemplate(template || null);
  };

  const handleFieldChange = (
    field: keyof Omit<TrainingTemplate, "ID" | "CreditID">,
    value: string | boolean
  ) => {
    if (selectedTemplate) {
      setSelectedTemplate({
        ...selectedTemplate,
        [field]: value,
      });
    }
  };

  const handleAddCard = () => {
    if (newCardName.trim() !== "" && selectedTemplate) {
      const updatedCards: Card[] = [
        ...selectedTemplate.Cards.Card,
        { Organisation: newCardName },
      ];
      setSelectedTemplate({
        ...selectedTemplate,
        Cards: { Card: updatedCards },
      });
      setNewCardName("");
    }
  };

  const handleRemoveCard = (index: number) => {
    if (selectedTemplate) {
      const updatedCards = selectedTemplate.Cards.Card.filter(
        (_, i) => i !== index
      );
      setSelectedTemplate({
        ...selectedTemplate,
        Cards: { Card: updatedCards },
      });
    }
  };

  const handleSaveTemplate = () => {
    if (selectedTemplate) {
      updateTrainingTemplate(selectedTemplate);
    }
  };

  const UrlField = ({
    label,
    field,
  }: {
    label: string;
    field: keyof TrainingTemplate;
  }) => (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        <Input
          value={selectedTemplate ? selectedTemplate[field] : ""}
          onChange={(e) => handleFieldChange(field, e.target.value)}
          fullWidth
        />
        <IconButton
          color="primary"
          onClick={() =>
            window.open(
              selectedTemplate ? selectedTemplate[field] : "",
              "_blank"
            )
          }
          sx={{ marginLeft: 2 }}
        >
          <OpenInNewIcon />
        </IconButton>
      </Box>
    </FormControl>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        <Select
          placeholder="Formation"
          value={selectedTemplate?.CreditID.toString() || ""}
          onChange={(e, newValue) => handleChangeTemplate(newValue)}
          sx={{ minWidth: "400px", fontSize: "15px" }}
        >
          {templates?.map((q) => (
            <Option key={q.ID} value={q.CreditID.toString()}>
              {q.Agency + " - " + q.Qualification}
            </Option>
          ))}
        </Select>
        {isLoading && (
          <Box sx={{ display: "flex", marginLeft: "10px", width: "150px" }}>
            <CircularProgress />
          </Box>
        )}
        {selectedTemplate && (
          <IconButton onClick={handleSaveTemplate}>
            <SSave />
          </IconButton>
        )}
      </Box>
      <Divider />
      {selectedTemplate ? (
        <Box sx={{ marginTop: "20px", width: "100%" }}>
          <Grid container spacing={2} direction="column">
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Zuurit CreditID</FormLabel>
                <Input
                  value={selectedTemplate.CreditID}
                  readOnly
                  sx={{ backgroundColor: "#f5f5f5", opacity: 0.8 }}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Organisation</FormLabel>
                <Input
                  value={selectedTemplate.Agency}
                  onChange={(e) => handleFieldChange("Agency", e.target.value)}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Qualification</FormLabel>
                <Input
                  value={selectedTemplate.Qualification}
                  onChange={(e) =>
                    handleFieldChange("Qualification", e.target.value)
                  }
                  fullWidth
                />
              </FormControl>
            </Grid>
            {/* URLs */}
            <Grid xs={12}>
              <UrlField label="Cours 1" field="Course1Url" />
            </Grid>
            <Grid xs={12}>
              <UrlField label="Cours 2" field="Course2Url" />
            </Grid>
            <Grid xs={12}>
              <UrlField label="Notes" field="TheoryUrl" />
            </Grid>
            <Grid xs={12}>
              <UrlField label="QCM" field="TestUrl" />
            </Grid>
            <Grid xs={12}>
              <UrlField label="Décharge médicale" field="MedicalAgreement" />
            </Grid>
            <Grid xs={12}>
              <UrlField
                label="Décharge de responsabilité"
                field="MedicalDisclaimer"
              />
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>Mail</FormLabel>
                <Input
                  value={selectedTemplate.MailTemplate}
                  onChange={(e) =>
                    handleFieldChange("MailTemplate", e.target.value)
                  }
                  fullWidth
                />
              </FormControl>
            </Grid>
            {/* Other fields */}
            <Grid xs={12}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <FormControl>
                    <FormLabel>Zuurit TrainingID</FormLabel>
                    <Input
                      value={selectedTemplate.ZTrainingId}
                      onChange={(e) =>
                        handleFieldChange("ZTrainingId", e.target.value)
                      }
                      fullWidth
                      type="number"
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl>
                    <FormLabel>Zuurit QualificationID</FormLabel>
                    <Input
                      value={selectedTemplate.ZQualificationId}
                      onChange={(e) =>
                        handleFieldChange("ZQualificationId", e.target.value)
                      }
                      fullWidth
                      type="number"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* SDI, Theory, Rifap fields */}
            <Grid xs={12}>
              <Grid container spacing={2}>
                <Grid xs={4}>
                  <Typography>SDI ?</Typography>
                  <Select
                    value={selectedTemplate.IsSDI ? "Oui" : "Non"}
                    onChange={(e, newValue) =>
                      handleFieldChange("IsSDI", newValue === "Oui")
                    }
                  >
                    <Option value="Oui">Oui</Option>
                    <Option value="Non">Non</Option>
                  </Select>
                </Grid>
                <Grid xs={4}>
                  <Typography>Cours théorique ?</Typography>
                  <Select
                    value={selectedTemplate.NeedsTheory ? "Oui" : "Non"}
                    onChange={(e, newValue) =>
                      handleFieldChange("NeedsTheory", newValue === "Oui")
                    }
                  >
                    <Option value="Oui">Oui</Option>
                    <Option value="Non">Non</Option>
                  </Select>
                </Grid>
                <Grid xs={4}>
                  <Typography>Rifap ?</Typography>
                  <Select
                    value={selectedTemplate.NeedsRifap ? "Oui" : "Non"}
                    onChange={(e, newValue) =>
                      handleFieldChange("NeedsRifap", newValue === "Oui")
                    }
                  >
                    <Option value="Oui">Oui</Option>
                    <Option value="Non">Non</Option>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            {/* Cards Section */}
            <Grid xs={12}>
              <Divider sx={{ marginY: 2 }} />
              <Typography>Cartes</Typography>
              {selectedTemplate.Cards.Card.map((card, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid xs={10}>
                    <Input
                      value={card.Organisation}
                      fullWidth
                      readOnly
                      size="sm"
                    />
                  </Grid>
                  <Grid xs={2}>
                    <IconButton onClick={() => handleRemoveCard(index)}>
                      <SDelete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid
                container
                spacing={2}
                sx={{ marginTop: 2 }}
                alignItems="center"
              >
                <Grid xs={10}>
                  <Input
                    placeholder="Ajouter une organisation"
                    value={newCardName}
                    onChange={(e) => setNewCardName(e.target.value)}
                    fullWidth
                    size="sm"
                  />
                </Grid>
                <Grid xs={2}>
                  <IconButton color="primary" onClick={handleAddCard} size="sm">
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Typography level="h4" sx={{ textAlign: "center", marginTop: "20px" }}>
          Sélectionnez un template pour le modifier.
        </Typography>
      )}
    </Box>
  );
}
