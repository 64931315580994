import React, { Dispatch, SetStateAction, useRef } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/joy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import { base64ToBlob, convertBase64 } from "../../../utils/fileSaver";

type FileWithPDFComponentProps = {
  file: any;
  setFile: Dispatch<
    SetStateAction<string | Blob | File | ProgressEvent<FileReader> | undefined>
  >;
  title: string | undefined;
  pdf: string | undefined;
};

const FileWithPDFComponent: React.FC<FileWithPDFComponentProps> = ({
  file,
  setFile,
  title,
  pdf,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      const base64 = await convertBase64(file);
      setFile(base64);
    }
    setIsLoading(false);
  };

  const viewFile = () => {
    if (file) {
      const fileBlob = base64ToBlob(file);
      const fileURL = URL.createObjectURL(fileBlob);
      const pdfWindow = window.open("");
      pdfWindow?.document.write(
        `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
      );
    }
  };

  const handleIconButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          flex: 1,
        }}
      >
        {pdf ? (
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Tooltip title="Voir le document original">
              <IconButton color="primary" size="lg">
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
            <Typography fontSize="sm">Document original</Typography>
            <Typography fontSize="sm">
              (à télécharger, remplir et redéposer dans la zone de droite)
            </Typography>
          </a>
        ) : null}
      </Box>
      <Divider orientation="vertical" sx={{ marginX: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {!isLoading ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box display="flex" gap={2}>
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
                accept="application/pdf"
              />
              <Tooltip title="Ajouter document">
                <IconButton
                  color="primary"
                  size="lg"
                  onClick={handleIconButtonClick}
                >
                  +
                </IconButton>
              </Tooltip>
              {file && (
                <Tooltip title="Voir le document ajouté">
                  <IconButton color="primary" size="lg" onClick={viewFile}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box>
              {file ? (
                <Typography fontSize="sm" color="success">
                  Document renseigné
                </Typography>
              ) : (
                <Typography fontSize="sm" color="danger">
                  Document non renseigné
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default FileWithPDFComponent;