import React from "react";
import { Box, CircularProgress, List, ListItem, Typography } from "@mui/joy";
import { ActionLog } from "../../../../api/zekay/Zekay.typed";
import useZekay from "../../../../contexts/controllers/useZekay";

const ActionsLogsComponent: React.FC = () => {
  const [logs, setLogs] = React.useState<ActionLog[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getActionsLogs } = useZekay();

  React.useEffect(() => {
    setIsLoading(true);
    getActionsLogs()
      .then((l) => setLogs(l))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size="lg" />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "85vh", // Ajuste cette valeur selon tes besoins
            overflowY: "auto", // Permet le défilement vertical
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "8px",
            padding: 2,
          }}
        >
          <List>
            {logs?.map((log) => (
              <ListItem
                key={log.ID}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  paddingY: 1.5,
                }}
              >
                <Typography level="h4" sx={{ flex: 1, marginRight: 2 }}>
                  {new Date(log.Date).toLocaleString()}
                </Typography>
                <Typography level="h4" sx={{ flex: 2 }}>
                  {`${log.Username} - ${log.Action}`}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ActionsLogsComponent;