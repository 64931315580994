import { Training } from "../../../api/zekay/Zekay.typed";
import SessionItem from "./SessionsItem";
import SessionEmptyItem from "./SessionEmptyItem";
import { Box } from "@mui/joy";

type SessionsListProps = {
  training: Training;
  onSessionSave: () => void; // Prop to refresh the customer
};

export default function SessionsList(props: SessionsListProps) {
  const sessions = props.training.Sessions || [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "100%",
      }}
    >
      {sessions.length > 0 ? (
        sessions.map((session, index) => (
          <SessionItem
            key={session.ID}
            session={session}
            isLast={index === sessions.length - 1}
            onSessionSave={props.onSessionSave}
          />
        ))
      ) : (
        <SessionEmptyItem />
      )}
    </Box>
  );
}
