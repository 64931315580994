import { User } from "../../../../api/auth/Auth.typed";
import { Box, Typography } from "@mui/joy";

type UserItemProps = {
  user: User | undefined;
};

export default function UserItem({ user }: UserItemProps) {
  return (
    <Box
      sx={{
        display: "flex",
        paddingY: 1,
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box sx={{ width: "33%", paddingX: 1 }}>
        <Typography level="h4">{user?.Name}</Typography>
      </Box>
      <Box sx={{ width: "33%", paddingX: 1 }}>
        <Typography level="h4">{user?.Email}</Typography>
      </Box>
      <Box sx={{ width: "33%", paddingX: 1 }}>
        <Typography level="h4">{user?.Role}</Typography>
      </Box>
    </Box>
  );
}
