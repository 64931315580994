import { Typography, Switch, Box } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";

type GdprComponentProps = {
  gdpr: boolean | undefined;
  setGdpr: Dispatch<SetStateAction<boolean>>;
};

export default function GdprComponent({ gdpr, setGdpr }: GdprComponentProps) {
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGdpr(event.target.checked);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <Typography sx={{ marginBottom: 1 }}>
        Voulez-vous recevoir nos communications ?
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginRight: 2 }}>Non</Typography>
        <Switch
          checked={gdpr || false}
          onChange={handleSwitchChange}
          size="md"
          color="primary"
        />
        <Typography sx={{ marginLeft: 2 }}>Oui</Typography>
      </Box>
    </Box>
  );
}
