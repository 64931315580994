import React, { ChangeEvent } from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/joy";
import {
  SAddDocument,
  SDelete,
  SMissingFile,
  SView,
} from "../../../styles/StyledMUI";

interface DocumentTypeComponentProps {
  uploadFile: (event: any, type: string) => Promise<void>;
  deleteFile: (fileType: string) => Promise<void>;
  handleView: (type: string) => void;
  title: string;
  document: string | undefined;
  documentType: string;
}

export default function DocumentTypeComponent({
  uploadFile,
  deleteFile,
  handleView,
  title,
  document,
  documentType,
}: DocumentTypeComponentProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    uploadFile(e, documentType).then(() => setIsLoading(false));
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: "8px",
        padding: 2,
        textAlign: "center",
        width: "100%",
        backgroundColor: "#171717",
      }}
    >
      <Typography level="h4" mb={1} color="neutral">
        {title}
      </Typography>

      {!isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <label>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleUpload}
              accept="application/pdf"
            />
            <Tooltip title="Ajouter document">
              <IconButton color="primary" component="span">
                <SAddDocument sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </label>

          {document ? (
            <>
              <Tooltip title="Voir document">
                <IconButton onClick={() => handleView(documentType)}>
                  <SView sx={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Supprimer document">
                <IconButton
                  onClick={() => deleteFile(documentType)}
                  color="danger"
                >
                  <SDelete sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <SMissingFile sx={{ color: "orange" }} />
          )}
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size="sm" />
        </Box>
      )}
    </Box>
  );
}
