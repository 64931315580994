export const formatDate = (date: string): string => {
  const dateTime = new Date(date);
  const formatedDate = dateTime.toLocaleString();
  return formatedDate;
};


export const formatMonth = (month: number) => {
    const months = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", 
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];
    return months[month - 1];
  };
