import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { FormControl, Select, Typography, Option } from "@mui/joy";
import { useAlert } from "../../../../hooks/useAlert";
import useZekay from "../../../../contexts/controllers/useZekay";
import { Box } from "@mui/system";
import {
  StatDate,
  StatsHandler,
  TrainingStats,
} from "../../../../api/zekay/Zekay.typed";
import { formatMonth } from "../../../../utils/dateTime";

export default function TrainingCount() {
  const [dates, setDates] = React.useState<StatDate[]>();
  const [year, setYear] = React.useState<string>("");
  const [trainingStats, setTrainingStats] = React.useState<TrainingStats[]>();
  const { showAlert } = useAlert();
  const { getStatDates, getTrainingStatsByYear } = useZekay();

  React.useEffect(() => {
    getStatDates()
      .then((dates) => setDates(dates))
      .catch((error) => {
        if (error instanceof Error) {
          showAlert(error.message, "danger");
        }
      });
  }, []);

  const handleChangeByYear = (
    event: React.SyntheticEvent | null,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setYear(newValue);

      const date: StatsHandler = {
        Year: Number(newValue),
        Month: 0,
      };

      getTrainingStatsByYear(date)
        .then((stats) => setTrainingStats(stats))
        .catch((error) => {
          if (error instanceof Error) {
            showAlert(error.message, "danger");
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Typography level="h4" mb={2} sx={{ color: "#FCCB06" }}>
        Total des formations validées
      </Typography>
      <FormControl>
        <Select
          placeholder="Année"
          value={year}
          onChange={handleChangeByYear}
          sx={{ width: "100px" }}
        >
          {dates?.map((date, index) => (
            <Option key={index} value={date.Year.toString()}>
              {date.Year}
            </Option>
          ))}
        </Select>
      </FormControl>
      <Box padding={2}>
        <ResponsiveContainer width="100%" height="auto" aspect={4}>
          <LineChart
            data={trainingStats}
            margin={{
              top: 5,
              right: 50,
              left: 10,
              bottom: 5,
            }}
          >
            <Line
              type="monotone"
              dataKey="ValidationsCount"
              stroke="green"
              name="Validations"
            />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="Month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </React.Fragment>
  );
}
