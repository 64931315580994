import { Box } from "@mui/joy";
import { ZekayCustomer } from "../../../../api/zekay/Zekay.typed";
import CustomerItem from "./CustomerItem";

type CustomersListProps = {
  customers: ZekayCustomer[] | undefined;
};

export default function CustomersList(props: CustomersListProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      sx={{
        width: "100%",
        margin: "0 auto",
      }}
    >
      {props.customers && props.customers.length > 0
        ? props.customers.map((c) => <CustomerItem customer={c} key={c.Id} />)
        : null}
    </Box>
  );
}
