import { Box, Grid, Input } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";

type PhoneComponentProps = {
  phonenumber: string;
  setPhonenumber: Dispatch<SetStateAction<string>>;
};

export default function PhoneComponent(props: PhoneComponentProps) {
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/[^\d+]/g, ""); // Permet uniquement les chiffres et le "+"
    props.setPhonenumber(inputValue);
  };

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Input
            placeholder="+262....."
            value={props.phonenumber || ""}
            onChange={handlePhoneChange}
            sx={{ marginTop: "10px", width: "100%" }}
            inputMode="tel" // HTML5 attribut pour activer le clavier numérique adapté
          />
        </Grid>
      </Grid>
    </Box>
  );
}
