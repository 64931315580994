import React from "react";
import { AppConfigContext } from "./contexts/AppConfigContext";
import { AppConfig } from "./contexts/AppConfigContext";
import { zekayTheme } from "../src/styles/globalStyles";
import CustomersPage from "./components/customers/customer/CustomersPage";
import LoginPage from "./components/auth/LoginPage";
import ResetPasswordPage from "./components/auth/ResetPasswordPage";
import ResetRequestPage from "./components/auth/ResetRequestPage";
import CreateInvitedUserPage from "./components/auth/CreateInvitedUserPage";
import PortalPage from "./components/portal/PortalPage";
import MenuPage from "./components/menu/MenuPage";
import Home from "./components/Home";
import { AlertProvider } from "./contexts/AlertContext";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ConfirmationModalProvider } from "./contexts/ConfirmationContext";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import {
  SCreditCard,
  SGroups,
  SInvoice,
  SNotes,
  SProduct,
  SSchool,
  SSettings,
  SStats,
  STemplates,
  SUsers,
} from "./styles/StyledMUI";
import CardValidationList from "./components/trainings/cardValidation/cardValidationList";
import DashboardPage from "./components/nav/DashboardPage";
import TabsPage from "./components/nav/TabsPage";
import ProductComponent from "./components/finance/products/ProductsComponent";
import InvoicesComponent from "./components/finance/invoices/InvoicesComponent";
import UserList from "./components/settings/users/UserList";
import GroupsComponent from "./components/settings/groups/GroupsComponent";
import GeneralSettingsPage from "./components/settings/general/GeneralSettingsPage";
import TrainingTemplateEditor from "./components/trainings/templates/display/TrainingTemplatesEditor";
import CourseTemplateEditor from "./components/trainings/templates/display/CourseTemplateEditor";
import TrainingsList from "./components/trainings/trainings/display/TrainingsList";
import ExcursionCount from "./components/finance/stats/display/ExcursionCount";
import BoatCustomerCount from "./components/finance/stats/display/BoatCustomerCount";
import TopCustomers from "./components/finance/stats/display/TopCustomers";
import TrainingCount from "./components/finance/stats/display/TrainingCount";
import ActionsLogsComponent from "./components/settings/logs/display/ActionsLogsComponent";
import WorkerLogsComponent from "./components/settings/logs/display/WorkerLogsComponent";

export default function App() {
  const [appConfig, setAppConfig] = React.useState<AppConfig>(undefined!);

  React.useEffect(() => {
    fetch(`${window.location.origin}/config.json`)
      .then((response) => response.json())
      .then((data) => setAppConfig(data));
  }, []);

  const trainingsConfig = [
    { label: "En cours", component: <TrainingsList status={1} /> },
    { label: "Validées", component: <TrainingsList status={3} /> },
    { label: "Annulées", component: <TrainingsList status={4} /> },
  ];

  const trainingsTemplatesConfig = [
    { label: "Formations", component: <TrainingTemplateEditor /> },
    { label: "Suivis", component: <CourseTemplateEditor /> },
  ];

  const sidebarTrainings = [
    {
      label: "Cartes",
      icon: <SCreditCard />,
      component: <CardValidationList />,
    },
    {
      label: "Formations",
      icon: <SSchool />,
      component: <TabsPage title="Formations" tabs={trainingsConfig} />,
    },
    {
      label: "Modèles",
      icon: <STemplates />,
      component: <TabsPage title="Modèles" tabs={trainingsTemplatesConfig} />,
    },
  ];

  const statsConfig = [
    { label: "Sorties", component: <ExcursionCount /> },
    { label: "Occupation", component: <BoatCustomerCount /> },
    { label: "Clients", component: <TopCustomers /> },
    { label: "Formations", component: <TrainingCount /> },
  ];

  const sidebarFinance = [
    {
      label: "Produits",
      icon: <SProduct />,
      component: <ProductComponent />,
    },
    {
      label: "Factures",
      icon: <SInvoice />,
      component: <InvoicesComponent />,
    },
    {
      label: "Statistiques",
      icon: <SStats />,
      component: <TabsPage title="Statistiques" tabs={statsConfig} />,
    },
  ];

  const logsConfig = [
    { label: "Activité", component: <ActionsLogsComponent /> },
    { label: "Synchro", component: <WorkerLogsComponent /> },
  ];

  const sidebarSettings = [
    {
      label: "Général",
      icon: <SSettings />,
      component: <GeneralSettingsPage />,
    },
    {
      label: "Logs",
      icon: <SNotes />,
      component: <TabsPage title="Logs" tabs={logsConfig} />,
    },
    {
      label: "Utilisateurs",
      icon: <SUsers />,
      component: <UserList />,
    },
    {
      label: "Groupes",
      icon: <SGroups />,
      component: <GroupsComponent />,
    },
  ];

  return (
    <AppConfigContext.Provider value={appConfig}>
      <CssVarsProvider theme={zekayTheme}>
        <CssBaseline />
        {appConfig !== undefined ? (
          <AlertProvider>
            <AuthProvider>
              <ConfirmationModalProvider>
                <Routes>
                  <Route path="/reset" element={<ResetPasswordPage />} />
                  <Route path="/request" element={<ResetRequestPage />} />
                  <Route path="/create" element={<CreateInvitedUserPage />} />
                  <Route path="/portal" element={<PortalPage />} />
                  <Route path="/" element={<Home />}>
                    <Route path="customers" element={<CustomersPage />} />
                    <Route
                      path="trainings"
                      element={
                        <DashboardPage sidebarConfig={sidebarTrainings} />
                      }
                    />
                    <Route
                      path="finance"
                      element={<DashboardPage sidebarConfig={sidebarFinance} />}
                    />
                    <Route
                      path="settings"
                      element={
                        <DashboardPage sidebarConfig={sidebarSettings} />
                      }
                    />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="menu" element={<MenuPage />} />
                    <Route path="*" element={<LoginPage />} />
                  </Route>
                </Routes>
              </ConfirmationModalProvider>
            </AuthProvider>
          </AlertProvider>
        ) : null}
      </CssVarsProvider>
    </AppConfigContext.Provider>
  );
}
