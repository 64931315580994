import React from "react";
import { Box } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import useAuth from "../../contexts/controllers/useAuth";
import SideBar from "../nav/SideBar";

type DashboardPageProps = {
  sidebarConfig: {
    label: string;
    icon: JSX.Element;
    component: JSX.Element;
  }[];
};

export default function DashboardPage({ sidebarConfig }: DashboardPageProps) {
  const [page, setPage] = React.useState(sidebarConfig[0].component);
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin()) navigate("/menu");
  }, [isAdmin, navigate]);

  return (
    <Box sx={{ display: "flex", height: "100vh", position: "relative" }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: "60px", // Sidebar étroite par défaut
          transition: "width 0.3s ease", // Transition pour le survol
          "&:hover": {
            width: "170px", // Largeur élargie au survol
          },
          borderRight: "1px solid #ccc",
          bgcolor: "background.body",
        }}
      >
        <SideBar config={sidebarConfig} setPage={setPage} />
      </Box>

      {/* Contenu principal */}
      <Box
        sx={{
          flexGrow: 1, // Prend tout l'espace restant
          overflow: "hidden", // Empêche le scroll global
          display: "flex",
          flexDirection: "column",
        }}
      >
        {page}
      </Box>
    </Box>
  );
}
