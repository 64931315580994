import React from "react";
import {
  Box,
  FormControl,
  Select,
  Option,
  Card,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Input,
} from "@mui/joy";
import { Module, Criteria } from "../../../../api/zekay/Zekay.typed";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddCircle from "@mui/icons-material/AddCircle";
import { SAddCriteria, SDelete } from "../../../../styles/StyledMUI";

interface ModuleFormProps {
  module: Module;
  onChange: (updatedModule: Module) => void;
  onDeleteModule: () => void;
}

export default function ModuleForm({
  module,
  onChange,
  onDeleteModule,
}: ModuleFormProps) {
  const [savedCustomOptions, setSavedCustomOptions] = React.useState<
    string[][]
  >([]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...module, name: e.target.value });
  };

  const handleCriteriaChange = (index: number, updatedCriteria: Criteria) => {
    const updatedCriteriaList = [...module.criteria];
    updatedCriteriaList[index] = updatedCriteria;
    onChange({ ...module, criteria: updatedCriteriaList });
  };

  const handleOptionsTypeChange = (index: number, newValue: string | null) => {
    const updatedCriteria = [...module.criteria];
    if (newValue === "default") {
      if (!savedCustomOptions[index]) {
        savedCustomOptions[index] = [...updatedCriteria[index].options];
        setSavedCustomOptions([...savedCustomOptions]);
      }
      updatedCriteria[index].options = ["OK", "A retravailler"];
    } else if (newValue === "custom") {
      if (savedCustomOptions[index]) {
        updatedCriteria[index].options = [...savedCustomOptions[index]];
      } else {
        updatedCriteria[index].options = [""];
      }
    }
    onChange({ ...module, criteria: updatedCriteria });
  };

  const addOption = (index: number) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[index].options.push("");
    onChange({ ...module, criteria: updatedCriteria });
  };

  const deleteOption = (criteriaIndex: number, optionIndex: number) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[criteriaIndex].options.splice(optionIndex, 1);
    onChange({ ...module, criteria: updatedCriteria });
  };

  const handleOptionChange = (
    criteriaIndex: number,
    optionIndex: number,
    value: string
  ) => {
    const updatedCriteria = [...module.criteria];
    updatedCriteria[criteriaIndex].options[optionIndex] = value;
    onChange({ ...module, criteria: updatedCriteria });
  };

  const handleDeleteCriteria = (index: number) => {
    const updatedCriteriaList = [...module.criteria];
    updatedCriteriaList.splice(index, 1);
    onChange({ ...module, criteria: updatedCriteriaList });
  };

  const addCriteria = () => {
    onChange({
      ...module,
      criteria: [
        ...module.criteria,
        { description: "", options: ["OK", "A retravailler"], comments: true },
      ],
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const updatedCriteriaList = Array.from(module.criteria);
    const [reorderedItem] = updatedCriteriaList.splice(result.source.index, 1);
    updatedCriteriaList.splice(result.destination.index, 0, reorderedItem);

    onChange({ ...module, criteria: updatedCriteriaList });
  };

  return (
    <Card
      sx={{
        padding: 2,
        marginBottom: 3,
        backgroundColor: "#F9F9F9",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Input
          placeholder="Nom du Module"
          value={module.name}
          onChange={handleNameChange}
          fullWidth
          sx={{ marginBottom: 2 }}
        />
      </Box>

      <Box sx={{ maxWidth: "80vw", overflowY: "auto" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="criteria">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {module.criteria.map((criteria, index) => (
                  <Draggable
                    key={index}
                    draggableId={`criteria-${index}`}
                    index={index}
                  >
                    {(provided) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          padding: 2,
                          marginBottom: 2,
                          backgroundColor: "#EEEEEE",
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
                          position: "relative",
                        }}
                      >
                        <Grid container alignItems="center" spacing={2}>
                          <Grid xs={10}>
                            <Input
                              placeholder="Description du Critère"
                              value={criteria.description}
                              onChange={(e) =>
                                handleCriteriaChange(index, {
                                  ...criteria,
                                  description: e.target.value,
                                })
                              }
                              fullWidth
                              sx={{ marginBottom: 2 }}
                            />
                            <FormControl>
                              <Select
                                value={
                                  criteria.options[0] === "OK" &&
                                  criteria.options[1] === "A retravailler"
                                    ? "default"
                                    : "custom"
                                }
                                onChange={(e, newValue) =>
                                  handleOptionsTypeChange(index, newValue)
                                }
                                placeholder="Options"
                                sx={{ marginBottom: 2 }}
                              >
                                <Option value="default">
                                  Par défaut: "OK / A retravailler"
                                </Option>
                                <Option value="custom">
                                  Options configurables
                                </Option>
                              </Select>
                            </FormControl>

                            {criteria.options[0] !== "OK" && (
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                textAlign="center"
                              >
                                {criteria.options.map((option, optIndex) => (
                                  <React.Fragment key={optIndex}>
                                    <Grid xs={2}>
                                      <Input
                                        placeholder={`Option ${optIndex + 1}`}
                                        value={option}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            index,
                                            optIndex,
                                            e.target.value
                                          )
                                        }
                                        fullWidth
                                      />
                                    </Grid>
                                    <Grid xs={1}>
                                      <Tooltip title="Supprimer option">
                                        <IconButton
                                          onClick={() =>
                                            deleteOption(index, optIndex)
                                          }
                                        >
                                          <SDelete sx={{ color: "red" }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                                <Grid xs={1}>
                                  <Tooltip title="Ajouter option">
                                    <AddCircle
                                      sx={{
                                        cursor: "pointer",
                                        color: "orange",
                                      }}
                                      onClick={() => addOption(index)}
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>

                          <Box
                            sx={{ position: "absolute", bottom: 10, right: 10 }}
                          >
                            <Tooltip title="Supprimer critère">
                              <IconButton
                                onClick={() => handleDeleteCriteria(index)}
                              >
                                <SDelete sx={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Grid>
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Supprimer Module">
          <IconButton sx={{ marginRight: 2 }} onClick={onDeleteModule}>
            <SDelete sx={{ color: "red" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Ajouter critère">
          <IconButton onClick={addCriteria}>
            <SAddCriteria sx={{ color: "orange" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
}
