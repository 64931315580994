import * as React from "react";
import { Box, Typography, Tab, TabList, Tabs, TabPanel } from "@mui/joy";

type TabConfig = {
  label: string;
  component: React.ReactNode;
};

type TabsPageProps = {
  title: string;
  tabs: TabConfig[];
};

export default function TabsPage({ title, tabs }: TabsPageProps) {
  const [value, setValue] = React.useState<number | null>(0);

  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: string | number | null
  ) => {
    setValue(newValue as number);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Titre en haut */}
      <Box
        sx={{
          padding: 2,
          borderBottom: "1px solid #FFFFFF",
          backgroundColor: "black",
        }}
      >
        <Typography level="h4" sx={{ color: "#FCCB06" }}>
          {title}
        </Typography>
      </Box>

      {/* Contenu principal avec disposition en flex */}
      <Box sx={{ display: "flex", flexGrow: 1, backgroundColor: "#121212" }}>
        {/* TabList vertical à gauche */}
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <TabList
            sx={{
              width: "150px", // Largeur figée de la TabList
              borderRight: "1px solid #FFFFFF",
              backgroundColor: "#1E1E1E",
              flexShrink: 0,
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                sx={{
                  color: "#FFFFFF",
                  "&:hover": { backgroundColor: "#505050" },
                  "&.Mui-selected": {
                    color: "#000000",
                    backgroundColor: "#FCCB06",
                    fontWeight: "bold",
                  },
                }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabList>
          {tabs.map((tab, index) => (
            <TabPanel
              key={index}
              value={index}
              sx={{
                flexGrow: 1, // Le panneau prend tout l'espace restant
                padding: 2,
                backgroundColor: "#121212", // Couleur de fond ajustée
              }}
            >
              {tab.component}
            </TabPanel>
          ))}
        </Tabs>
      </Box>
    </Box>
  );
}