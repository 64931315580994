import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Typography,
  Badge,
  IconButton,
  Tooltip,
  Input,
  Link,
} from "@mui/joy";
import {
  Card,
  Cards,
  Mail,
  Training,
  ZekayCustomer,
} from "../../../api/zekay/Zekay.typed";
import useZekay from "../../../contexts/controllers/useZekay";
import dayjs, { Dayjs } from "dayjs";
import useAuth from "../../../contexts/controllers/useAuth";
import ProfileForm from "../../customers/profiles/ProfileForm";
import CommentsComponent from "../../common/CommentsComponent";
import DatesComponent from "../../common/DatesComponent";
import DocumentsComponent from "../../common/DocumentsComponent";
import {
  SDate,
  SDelete,
  SFile,
  SNotes,
  SOpenProfile,
  SRedirect,
  SSave,
  SSendMail,
  SValidate,
} from "../../../styles/StyledMUI";
import CardOrganisationStatus from "./display/cardOrganisationStatus";
import DetailsTooltip from "../../common/DetailsTooltip";
import { useAlert } from "../../../hooks/useAlert";

type CardValidationItemProps = {
  training: Training;
  setTrainings: Dispatch<SetStateAction<Training[] | undefined>>;
};

export default function CardValidationItem(props: CardValidationItemProps) {
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const {
    getCustomer,
    updateTraining,
    validateUserQualification,
    getDoneTrainings,
    cancelTraining,
    sendMail,
  } = useZekay();
  const { user } = useAuth();
  const [comments, setComments] = React.useState(props.training.Comments);
  const [testResult, setTestResult] = React.useState(
    props.training.TestResult || ""
  );
  const [isOpenComments, setIsOpenComments] = React.useState(false);
  const [isOpenDates, setIsOpenDates] = React.useState(false);
  const [isOpenDocuments, setIsOpenDocuments] = React.useState(false);
  const [profileFormOpen, setProfileFormOpen] = React.useState(false);
  const [cards, setCards] = React.useState<Card[]>([]);
  const [theory, setTheory] = React.useState<Dayjs | null>(
    props.training.Theory ? dayjs(props.training.Theory) : null
  );

  const [rifap, setRifap] = React.useState<Dayjs | null>(
    props.training.RifapDate ? dayjs(props.training.RifapDate) : null
  );

  const { showAlert } = useAlert();

  const handleTestResultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestResult(e.target.value.replace(/\D/g, ""));
  };

  const handleUpdate = () => {
    const updatedTraining = {
      ...props.training,
      Comments: comments,
      TestResult: testResult,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };
    updateTraining(updatedTraining)
      .then(getDoneTrainings)
      .then((ts) => props.setTrainings(ts));
  };

  const handleCancelTraining = () => {
    const updatedTraining = {
      ...props.training,
      Status: "Annulé",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    cancelTraining(updatedTraining)
      .then(() => getDoneTrainings())
      .then((ts) => props.setTrainings(ts));
  };

  const handleValidate = () => {
    const validatedTraining = {
      ...props.training,
      Status: "Validé",
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    validateUserQualification(validatedTraining)
      .then(getDoneTrainings)
      .then((ts) => props.setTrainings(ts));
  };

  const handleClickOpenProfile = () => {
    getCustomer(String(props.training.CustomerID))
      .then((r) => setCustomer(r))
      .then(() => setProfileFormOpen(true));
  };

  const handleSave = () => {
    const cs: Cards = {
      Card: cards,
    };

    const tr: Training = {
      ...props.training,
      Comments: comments,
      Cards: cs,
      UpdatedBy: user?.Name,
      UpdatedAt: new Date().toISOString(),
    };

    updateTraining(tr)
      .then(() => getDoneTrainings())
      .then((ts) => props.setTrainings(ts));
  };

  const handleSendMail = async () => {
    const customer = await getCustomer(String(props.training?.CustomerID));
    if (customer?.MailAddress) {
      const mailData: Mail = {
        ZuuritID: customer.Id,
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        MailAddress: customer.MailAddress,
        CreditID: props.training?.CreditId,
      };
      await sendMail(mailData);
      showAlert("Mail envoyé !", "success");
    }
  };

  return (
    <>
      <tr>
        <td>
          <Typography level="h4">{props.training.FirstName}</Typography>
        </td>
        <td>
          <Typography level="h4">{props.training.LastName}</Typography>
        </td>
        <td>
          <Typography level="h4">{props.training.Agency}</Typography>
        </td>
        <td>
          <Typography level="h4">{props.training.Qualification}</Typography>
        </td>
        <td>
          <Badge badgeContent={comments ? 1 : 0} size="sm" color="neutral">
            <IconButton onClick={() => setIsOpenComments(!isOpenComments)}>
              <SNotes />
            </IconButton>
          </Badge>
        </td>
        <td>
          {props.training.NeedsTheory ? (
            <IconButton onClick={() => setIsOpenDates(!isOpenDates)}>
              <SDate />
            </IconButton>
          ) : (
            <Typography>N/A</Typography>
          )}
        </td>
        <td>
          <IconButton onClick={() => setIsOpenDocuments(!isOpenDocuments)}>
            <SFile />
          </IconButton>
        </td>
        <td>
          <Box display="flex">
            <Input
              value={testResult}
              onChange={handleTestResultChange}
              endDecorator="%"
              sx={{ width: 90 }}
            />
            <IconButton onClick={handleUpdate}>
              <SSave sx={{ color: "green" }} />
            </IconButton>
          </Box>
        </td>
        <td>
          <IconButton onClick={handleClickOpenProfile}>
            <SOpenProfile />
          </IconButton>
        </td>
        <td>
          <IconButton onClick={handleSendMail}>
            <SSendMail />
          </IconButton>
        </td>
        <td>
          <Link
            href={`https://app.zuurit.com/customers/${props.training.CustomerID}`}
            target="_blank"
          >
            <SRedirect />
          </Link>
        </td>
        <td>
          {props.training?.Cards?.Card !== null
            ? props.training?.Cards?.Card.map((c: Card, index: number) => {
                return (
                  <CardOrganisationStatus
                    key={index}
                    index={index}
                    cardOrganisation={c.Organisation}
                    cardStatus={c.Status !== undefined ? c.Status : false}
                    cards={cards}
                    setCards={setCards}
                    handleSave={handleSave}
                  />
                );
              })
            : null}
        </td>
        <td>
          <IconButton onClick={handleValidate}>
            <SValidate sx={{ color: "green" }} />
          </IconButton>
        </td>
        <td>
          <Tooltip title="Annuler">
            <IconButton onClick={handleCancelTraining}>
              <SDelete sx={{ color: "red" }} />
            </IconButton>
          </Tooltip>
        </td>
        <td>
          <DetailsTooltip training={props.training} />
        </td>
      </tr>

      {/* Modals */}
      {isOpenComments && (
        <CommentsComponent
          comments={comments}
          setComments={setComments}
          handleUpdate={handleUpdate}
          isOpenComments={false}
          setIsOpenComments={setIsOpenComments}
        />
      )}
      {isOpenDates && (
        <DatesComponent
          training={props.training}
          theory={theory}
          rifap={rifap}
          setTheory={setTheory}
          setRifap={setRifap}
          updateTraining={updateTraining}
          getTrainingsByCreditId={getDoneTrainings}
          setTrainings={props.setTrainings}
          isOpenDates={isOpenDates}
        />
      )}
      {isOpenDocuments && (
        <DocumentsComponent
          training={props.training}
          setTrainings={props.setTrainings}
          refreshTrainings={getDoneTrainings}
          isOpenDocuments={isOpenDocuments}
        />
      )}
      {profileFormOpen && (
        <tr>
          <td colSpan={12} style={{ border: "none" }}>
            <ProfileForm
              customer={customer}
              setProfileFormOpen={setProfileFormOpen}
              setCustomerUpdated={setCustomer}
            />
          </td>
        </tr>
      )}
    </>
  );
}
