import { Typography } from "@mui/joy";

export default function TrainingEmptyList() {
  return (
    <>
      <tr>
        <td>
          <Typography level="h4">Aucun résultat</Typography>
        </td>
      </tr>
    </>
  );
}
