import React, { Dispatch, SetStateAction } from "react";
import {
  Modal,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/joy";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { File, Training, ZekayCustomer } from "../../../api/zekay/Zekay.typed";
import { SClose, SGenerate } from "../../../styles/StyledMUI";
import useAuth from "../../../contexts/controllers/useAuth";
import useZekay from "../../../contexts/controllers/useZekay";
import { convertBase64 } from "../../../utils/fileSaver";
import { useLocation } from "react-router-dom";

type RefreshFunction<T> = () => Promise<T>;
type RefreshFunctionWithId<T> = (id?: string) => Promise<T>;

type RefreshTrainingFunction =
  | RefreshFunction<Training[] | undefined>
  | RefreshFunctionWithId<Training[] | undefined>;

type TrainingSummaryModalProps = {
  open: boolean;
  onClose: () => void;
  customerId: number | undefined;
  creditId: number | undefined;
  setTrainings?: Dispatch<SetStateAction<Training[] | undefined>>;
  refreshTrainings?: RefreshTrainingFunction;
};

type CriteriaSummary = {
  description: string;
  status: string;
  comments: Array<{ sessionNumber: number; comment: string }>;
  isComplete: boolean;
};

async function generateModuleSummary(
  training: Training
): Promise<Record<string, CriteriaSummary[]>> {
  const moduleSummaries: Record<string, CriteriaSummary[]> = {};

  if (!Array.isArray(training.Sessions)) {
    return moduleSummaries; // Retourne un résumé vide si aucune session
  }

  training.Sessions.forEach((session) => {
    if (!session.Form || !Array.isArray(session.Form.modules)) {
      return; // Ignorer si Form ou modules est invalide
    }

    session.Form.modules.forEach(
      (module: { name: string; criteria: any[] }) => {
        if (!moduleSummaries[module.name]) {
          moduleSummaries[module.name] = [];
        }

        module.criteria.forEach((criterion: any) => {
          const existingSummary = moduleSummaries[module.name].find(
            (c) => c.description === criterion.description
          );

          const options = criterion.options || [];
          const responses = criterion.status || [];
          const missingOptions = options.filter(
            (opt: string) => !responses.includes(opt)
          );

          let status = "";

          if (options.includes("OK") && options.includes("A retravailler")) {
            // Cas spécial "OK" ou "A retravailler"
            status = `${responses.join(", ") || "Non rempli"}`;
          } else {
            // Autres cas
            const given = responses.length
              ? `Validé : ${responses.join(", ")}\n`
              : "";
            const missing = missingOptions.length
              ? `Manquant : ${missingOptions.join(", ")}`
              : "";

            status = `${given}${missing}`;
          }

          // Calcul de `isComplete`
          let isComplete = false;
          if (options.includes("OK") && options.includes("A retravailler")) {
            // Si les options sont "OK" ou "A retravailler"
            isComplete = responses.includes("OK");
          } else {
            // Si toutes les options disponibles ont été validées
            isComplete = missingOptions.length === 0;
          }

          if (existingSummary) {
            existingSummary.status = status;
            existingSummary.isComplete = isComplete;

            if (criterion.comments_value) {
              const lastComment =
                existingSummary.comments[existingSummary.comments.length - 1]
                  ?.comment;
              if (lastComment !== criterion.comments_value) {
                existingSummary.comments.push({
                  sessionNumber: session.Number ?? 0,
                  comment: criterion.comments_value,
                });
              }
            }
          } else {
            moduleSummaries[module.name].push({
              description: criterion.description,
              status,
              comments: criterion.comments_value
                ? [
                    {
                      sessionNumber: session.Number ?? 0,
                      comment: criterion.comments_value,
                    },
                  ]
                : [],
              isComplete,
            });
          }
        });
      }
    );
  });

  return moduleSummaries;
}

const getColorForCriterion = (criterion: CriteriaSummary): string => {
  // Si le statut contient "OK", on considère que le critère est complété
  if (criterion.status.includes("OK")) {
    criterion.isComplete = true; // Marquer comme complété
  }

  // Si le critère est complété, retourner "green"
  if (criterion.isComplete) {
    return "green";
  }

  if (
    criterion.status.includes("Manquant") &&
    criterion.status.includes("Validé")
  ) {
    return "orange";
  }

  // Si le statut contient "A retravailler", retourner "orange"
  if (criterion.status.includes("A retravailler")) {
    return "orange";
  }

  // Sinon, retourner "white"
  return "white";
};

function TrainingSummaryModal(props: TrainingSummaryModalProps) {
  const { getCustomer, updateTrainingFile, getTrainingByUserCreditId } =
    useZekay();
  const [training, setTraining] = React.useState<Training>();
  const [moduleSummaries, setModuleSummaries] = React.useState<
    Record<string, CriteriaSummary[]>
  >({});
  const [customer, setCustomer] = React.useState<ZekayCustomer>();
  const { user, isAdmin } = useAuth();
  const location = useLocation();
  const isSmallScreen = window.innerWidth <= 600;
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (!props.customerId || !props.creditId) {
      return;
    }

    setIsLoading(true);

    getCustomer(String(props.customerId))
      .then((customerData) => {
        setCustomer(customerData);
        return getTrainingByUserCreditId(props.creditId!, props.customerId!);
      })
      .then((trainingData) => {
        if (trainingData) {
          setTraining(trainingData);
          return generateModuleSummary(trainingData);
        }
        return undefined;
      })
      .then((summaries) => {
        if (summaries) {
          setModuleSummaries(summaries);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.open]);

  const headerStyles = {
    container: {
      position: "fixed",
      top: 10,
      left: 10,
      right: 10,
      height: "calc(100% - 30px)",
      overflow: "auto",
      borderRadius: "10px",
      padding: "clamp(0.5rem, 1.5vw, 1rem)",
      backgroundColor: "white",
      margin: "0 auto", // Centrer sur PC
    },
    headerTypography: {
      color: "black",
      fontWeight: "bold",
      fontSize: "clamp(1rem, 3vw, 1.5rem)",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "5% 17% 15% 8% 7% 9% 20%",
      gap: 1,
      padding: 1,
    },
    gridTypographyTitle: {
      color: "black",
      fontWeight: "bold",
      fontSize: "clamp(0.7rem, 2vw, 1rem)",
    },
    gridTypography: {
      color: "black",
      fontSize: "clamp(0.7rem, 2vw, 1rem)",
    },
  };

  const modulesStyles = {
    moduleContainer: {
      marginBottom: 4,
    },
    moduleHeader: {
      marginBottom: 1,
      marginTop: 2,
      color: "black",
      fontWeight: "bold",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "2% 20% 20% 30%",
      gap: 1,
      padding: 1,
    },
    gridTypographyTitle: {
      color: "black",
      fontWeight: "bold",
      fontSize: "clamp(0.7rem, 2vw, 1rem)",
    },
    gridTypography: {
      color: "black",
      fontSize: "clamp(0.7rem, 2vw, 1rem)",
    },
  };

  const uploadSheetFile = (file: any) => {
    // Vérifier si les props nécessaires sont définies
    if (!props.customerId || !props.creditId) {
      return; // Sortir si l'ID client ou l'ID crédit est manquant
    }

    convertBase64(file).then((base64) => {
      const f: File = {
        CustomerID: props.customerId,
        CreditID: props.creditId,
        Type: "sheet",
        File: base64,
      };

      updateTrainingFile(f).then(() => {
        if (props.refreshTrainings) {
          props.refreshTrainings().then((updatedTrainings) => {
            if (props.setTrainings && updatedTrainings) {
              props.setTrainings(updatedTrainings);
            }
          });
        }
      });
    });
  };

  const handleGeneratePdf = async () => {
    if (customer !== undefined) {
      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      const margin = 14; // Marge à gauche
      let cursorY = 35; // Position de départ sous l'en-tête

      // Load the image
      const logoUrl = require("../../../styles/images/escapade_logo.jpeg"); // Use require to load the image

      const getBase64Image = (imgUrl: any) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = imgUrl;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
          };
          img.onerror = (error) => reject(error);
        });
      };

      const logoBase64 = await getBase64Image(logoUrl);

      // En-tête
      const header = () => {
        doc.setFontSize(10);
        doc.text(`Nom: ${customer.LastName} ${customer.FirstName}`, margin, 20);
        doc.text(`Formation: ${training?.Qualification}`, margin, 25);
        doc.setDrawColor(0);
        doc.setLineWidth(0.1);
        // Add the logo in the top right corner
        doc.addImage(logoBase64, "JPEG", pageWidth - 60, 10, 50, 30); // Adjust the position and size as needed
      };

      // Ajouter l'en-tête sur la première page
      header();

      doc.setFontSize(14);
      cursorY += 5;

      const instructorColumn = [
        "Séances",
        "Date",
        "Moniteur",
        "Site",
        "Profondeur (m)",
        "Temps (min)",
        "Commentaire général",
      ];
      const instructorRows =
        training.Sessions?.map((session) => [
          `Séance ${session.Number}`,
          session.Date,
          session.Instructor,
          session.DiveSite,
          session.DiveDepth,
          session.DiveTime,
          session.GlobalComment,
        ]) || [];

      doc.autoTable({
        startY: cursorY,
        head: [instructorColumn],
        body: instructorRows,
        styles: { cellPadding: 1, fontSize: 9 },
        didDrawPage: (data) => {
          cursorY = data.cursor.y;
        },
      });

      cursorY = doc.autoTableEndPosY() + 10; // Add spacing after the table

      Object.keys(moduleSummaries).forEach((moduleName, index) => {
        const module = moduleSummaries[moduleName];

        // Diviser le texte si le titre est trop long pour tenir sur une ligne
        const moduleTitleLines = doc.splitTextToSize(
          `${moduleName}`,
          pageWidth - 2 * margin
        );

        // Check if there is enough space for the title + some rows on the page, if not, add a new page
        if (cursorY + moduleTitleLines.length * 10 + 10 > pageHeight - 40) {
          // 40 to account for footer space on each page
          doc.addPage();
          cursorY = 20; // Reset cursorY after adding a new page
        }

        doc.setFontSize(14);
        doc.text(moduleTitleLines, margin, cursorY);
        cursorY += moduleTitleLines.length * 5;

        const tableColumn = ["Description", "Critères", "Commentaires"];
        const tableRows: any[] = [];

        module.forEach((criterion) => {
          const comments = criterion.comments.length
            ? criterion.comments
                .map((c) => `Séance ${c.sessionNumber}: ${c.comment}`)
                .join("\n")
            : "";

          const rowData = [criterion.description, criterion.status, comments];
          tableRows.push(rowData);
        });

        doc.autoTable({
          startY: cursorY,
          head: [tableColumn],
          body: tableRows,
          styles: { cellPadding: 1, fontSize: 8 },
          columnStyles: {
            0: { cellWidth: 50 },
            1: { cellWidth: 50 },
            2: { cellWidth: 80 },
          },
          didDrawCell: (data) => {
            // Ensure we only apply the color to body cells, not header cells
            if (data.row.section === "body" && data.column.index === 0) {
              const criterion = module[data.row.index];
              if (criterion) {
                // Add this check to avoid accessing undefined properties
                const color = getColorForCriterion(criterion);
                doc.setDrawColor(color);
                doc.setFillColor(color);
                doc.rect(
                  data.cell.x - 5,
                  data.cell.y,
                  1,
                  data.cell.height,
                  "F"
                );
              }
            }
          },
          didDrawPage: (data) => {
            cursorY = data.cursor.y; // Update the cursorY after drawing the table
          },
        });

        cursorY = doc.autoTableEndPosY() + 10; // Add some spacing after the table
      });

      // Ajouter le pied de page (signature) uniquement à la dernière page
      const date = new Date().toLocaleDateString();

      doc.setFontSize(10);
      doc.setDrawColor(0);
      doc.setLineWidth(0.5);
      doc.line(margin, pageHeight - 25, pageWidth - margin, pageHeight - 25); // Ligne au-dessus du pied de page
      doc.text(`Date: ${date}`, margin, pageHeight - 20);
      doc.text(`Signature : ${user?.Name}`, margin, pageHeight - 10);

      // Generate the PDF as a Blob
      const pdfBlob = doc.output("blob");

      uploadSheetFile(pdfBlob);

      props.onClose();
    }
  };

  if (isLoading) {
    return (
      <Modal open={props.open} onClose={props.onClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Remplir toute la hauteur
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <>
        <Box
          sx={{
            position: "fixed",
            top: 10,
            left: 10,
            right: 10,
            height: "calc(100% - 30px)",
            overflow: "auto",
            borderRadius: "10px",
            padding: "clamp(0.5rem, 2vw, 1rem)",
            backgroundColor: "white",
            margin: "0 auto", // Centrer sur PC
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography sx={headerStyles.headerTypography} mb={2}>
              {customer?.FirstName} {customer?.LastName} -{" "}
              {training?.Qualification}
            </Typography>
            <Box sx={headerStyles.gridContainer}>
              <Typography sx={headerStyles.gridTypographyTitle}>N°</Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                Date
              </Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                Moniteur
              </Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                Site
              </Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                P(m)
              </Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                T(min)
              </Typography>
              <Typography sx={headerStyles.gridTypographyTitle}>
                {isSmallScreen ? "Notes" : "Commentaires"}
              </Typography>
            </Box>

            {training?.Sessions?.map((session, idx) => (
              <Box
                key={idx}
                sx={{
                  ...headerStyles.gridContainer,
                  borderBottom: "1px solid grey",
                }}
              >
                <Typography sx={headerStyles.gridTypography}>
                  {session.Number}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.Date}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.Instructor}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.DiveSite}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.DiveDepth}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.DiveTime}
                </Typography>
                <Typography sx={headerStyles.gridTypography}>
                  {session.GlobalComment}
                </Typography>
              </Box>
            ))}
          </Box>

          {Object.keys(moduleSummaries).map((moduleName, index) => (
            <Box key={index}>
              <Typography sx={modulesStyles.moduleHeader}>
                {moduleName}
              </Typography>
              <Box sx={modulesStyles.gridContainer}>
                <Typography sx={modulesStyles.gridTypographyTitle}></Typography>
                <Typography sx={modulesStyles.gridTypographyTitle}>
                  Description
                </Typography>
                <Typography sx={modulesStyles.gridTypographyTitle}>
                  Critères
                </Typography>
                <Typography sx={modulesStyles.gridTypographyTitle}>
                  Commentaires
                </Typography>
              </Box>

              {moduleSummaries[moduleName].map((criterion, idx) => (
                <Box
                  key={idx}
                  sx={{
                    ...modulesStyles.gridContainer,
                    borderBottom: "1px solid grey",
                  }}
                >
                  <Typography
                    sx={{
                      borderLeft: `5px solid ${getColorForCriterion(
                        criterion
                      )}`,
                    }}
                  ></Typography>
                  <Typography sx={modulesStyles.gridTypography}>
                    {criterion.description}
                  </Typography>
                  <Typography sx={modulesStyles.gridTypography}>
                    {criterion.status.split("\n").map((line, index) => {
                      if (line.includes("Validé :")) {
                        return (
                          <span key={index}>
                            <strong style={{ color: "green" }}>
                              {line.split(":")[0]}:
                            </strong>{" "}
                            {line.split(":")[1]}
                            <br />
                          </span>
                        );
                      } else if (line.includes("Manquant :")) {
                        return (
                          <span key={index}>
                            <strong style={{ color: "orange" }}>
                              {line.split(":")[0]}:
                            </strong>{" "}
                            {line.split(":")[1]}
                            <br />
                          </span>
                        );
                      } else {
                        return (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        );
                      }
                    })}
                  </Typography>
                  <Typography sx={modulesStyles.gridTypography}>
                    {criterion.comments.length > 0 ? (
                      <ul>
                        {criterion.comments.map((comment, cIdx) => (
                          <li key={cIdx}>
                            Session {comment.sessionNumber}: {comment.comment}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            width: "150px",
            height: "60px",
            backgroundColor: "#E0E0E0",
            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px 0 0 0",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {isAdmin() && location.pathname === "/trainings" && (
              <Grid>
                <Tooltip title="Générer PDF">
                  <IconButton onClick={handleGeneratePdf}>
                    <SGenerate sx={{ color: "green" }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            <Grid>
              <Tooltip title="Fermer">
                <IconButton onClick={props.onClose}>
                  <SClose sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </>
    </Modal>
  );
}

export default TrainingSummaryModal;
