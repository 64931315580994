import React from "react";
import { Box, Link, Button, Typography } from "@mui/joy";
import { School, Description } from "@mui/icons-material";

interface TrainingDocsProps {
  course1: string | undefined;
  course2: string | undefined;
  theoryUrl: string | undefined;
}

export default function TrainingDocs(props: TrainingDocsProps) {
  const renderIconButton = (
    url: string,
    icon: React.ReactElement,
    description: string
  ) => (
    <Box textAlign="center">
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        sx={{ color: "inherit", textDecoration: "none" }}
      >
        <Button
          variant="solid"
          color="primary"
          size="lg"
          sx={{
            width: 56,
            height: 56,
            margin: 1,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {icon}
        </Button>
        <Typography fontSize="13px">{description}</Typography>
      </Link>
    </Box>
  );

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "black",
        fontFamily: '"Roboto", sans-serif',
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {props.course1 &&
          renderIconButton(
            props.course1,
            <School sx={{ color: "white" }} />,
            "Cours Escapade"
          )}
        {props.course2 &&
          renderIconButton(
            props.course2,
            <School sx={{ color: "white" }} />,
            "Cours SDI"
          )}
        {props.theoryUrl &&
          renderIconButton(
            props.theoryUrl,
            <Description sx={{ color: "white" }} />,
            "Mémo"
          )}
      </Box>
    </Box>
  );
}
