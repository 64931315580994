import React, { Dispatch, SetStateAction } from "react";
import { Card } from "../../../../api/zekay/Zekay.typed";
import { Box, Checkbox, Typography } from "@mui/joy";

type CardOrganisationStatusProps = {
  index: number;
  cardOrganisation: string;
  cardStatus: boolean;
  cards: Card[];
  setCards: Dispatch<SetStateAction<Card[]>>;
  handleSave: () => void;
};

export default function CardOrganisationStatus({
  index,
  cardOrganisation,
  cardStatus,
  cards,
  setCards,
  handleSave,
}: CardOrganisationStatusProps) {
  const [status, setStatus] = React.useState<boolean>(cardStatus);

  const handleChange = () => {
    setStatus((prevStatus) => !prevStatus);
    const updatedCards = [...cards];
    updatedCards[index].Status = !status;
    setCards(updatedCards);
    handleSave();
  };

  const updateCards = (updatedCard: Card, updatedIndex: number) => {
    setCards((cards: any) => {
      const updatedCards = cards;
      updatedCards[updatedIndex] = updatedCard;
      return updatedCards;
    });
  };

  React.useEffect(() => {
    const card: Card = {
      Organisation: cardOrganisation,
      Status: status,
    };
    updateCards(card, index);
  }, []);

  return (
    <Box display="flex" alignItems="center">
      <Checkbox
        checked={status}
        onChange={handleChange}
        variant="outlined"
        size="sm"
      />
      <Typography sx={{ ml: 1, color: "white" }}>
        {cardOrganisation.toUpperCase()}
      </Typography>
    </Box>
  );
}
