import {
  ListItem,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
  Sheet,
  List,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import AccountBox from "./AccountBox";
import { SHome } from "../../styles/StyledMUI";
import useZekay from "../../contexts/controllers/useZekay";
import React from "react";
import { RefreshOutlined } from "@mui/icons-material";

type SideBarProps = {
  setPage: (page: JSX.Element) => void;
  config: {
    label: string;
    icon: JSX.Element;
    component: JSX.Element;
  }[];
};

export default function SideBar({ setPage, config }: SideBarProps) {
  const navigate = useNavigate();
  const { getLogsByTable } = useZekay();
  const [updatedAt, setUpdatedAt] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchUpdateLog = async () => {
      const log = await getLogsByTable("customers");
      if (log) {
        setUpdatedAt(log.UpdatedAt);
      }
    };

    fetchUpdateLog();
  }, [getLogsByTable]);

  const handleNavigateHome = () => {
    navigate("/menu");
  };

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: "fixed", md: "sticky" },
        height: { xs: "auto", md: "100vh" },
        width: { xs: "100%", md: "60px" }, // Sidebar étroite par défaut
        top: 0,
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
        backgroundColor: "#121212",
        transition: "width 0.3s ease-in-out", // Transition pour l'élargissement
        "&:hover": {
          width: "170px", // Largeur élargie au survol
        },
      }}
    >
      {/* Logo / Home */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={handleNavigateHome}
          size="lg"
          variant="plain"
          sx={{ flexShrink: 0 }}
        >
          <SHome />
        </IconButton>
        <Typography
          onClick={handleNavigateHome}
          sx={{
            cursor: "pointer",
            color: "white",
            fontFamily: "monospace",
            fontSize: "15px",
            letterSpacing: ".1rem",
            whiteSpace: "nowrap",
            overflow: "hidden", // Empêche le débordement
            textOverflow: "ellipsis",
            opacity: 0, // Masqué par défaut
            transition: "opacity 0.3s ease-in-out",
            visibility: "hidden", // Masqué visuellement
            ".Sidebar:hover &": {
              opacity: 1, // Révélé au survol
              visibility: "visible",
            },
          }}
        >
          Zékay
        </Typography>
      </Box>

      {/* Divider */}
      <Divider sx={{ marginBottom: 1, backgroundColor: "whitesmoke" }} />

      {/* Menu Items */}
      <List
        size="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          color: "white",
        }}
      >
        {config.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: 0,
            }}
          >
            <ListItemButton
              onClick={() => setPage(item.component)}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(252, 203, 6, 0.2) !important", // Surbrillance au survol
                },
              }}
            >
              {/* Icon */}
              <ListItemDecorator
                sx={{
                  minWidth: "auto",
                  justifyContent: "center",
                  marginRight: "5px",
                  marginLeft: "5px",
                }}
              >
                {item.icon}
              </ListItemDecorator>

              {/* Label */}
              <ListItemContent
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: 0, // Masqué par défaut
                  visibility: "hidden", // Masqué visuellement
                  transition: "opacity 0.3s ease-in-out, visibility 0.3s",
                  ".Sidebar:hover &": {
                    opacity: 1, // Révélé au survol
                    visibility: "visible",
                  },
                }}
              >
                <Typography level="h4" sx={{ color: "white" }}>
                  {item.label}
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      {/* Info Icon */}
      <Box
        sx={{
          marginTop: "auto",
          marginBottom: 5, // Un peu d'espace avant l'AccountBox
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RefreshOutlined sx={{ marginRight: "10px", marginLeft: "5px" }} />
        <Typography
          sx={{
            fontStyle: "italic",
            overflow: "hidden",
            textOverflow: "ellipsis",
            opacity: 0, // Masqué par défaut
            visibility: "hidden", // Masqué visuellement
            transition: "opacity 0.3s ease-in-out, visibility 0.3s",
            ".Sidebar:hover &": {
              opacity: 1, // Révélé au survol
              visibility: "visible",
            },
          }}
        >
          {updatedAt
            ? `${new Date(updatedAt).toLocaleString()}`
            : "Chargement..."}
        </Typography>
      </Box>

      {/* Account Box */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          opacity: 0, // Masqué par défaut
          visibility: "hidden",
          transition: "opacity 0.3s ease-in-out, visibility 0.3s",
          ".Sidebar:hover &": {
            opacity: 1,
            visibility: "visible",
          },
        }}
      >
        <AccountBox />
      </Box>
    </Sheet>
  );
}
