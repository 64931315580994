import React, { Dispatch, SetStateAction } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/joy";
import {
  ZekayCustomer,
  Training,
  Session,
} from "../../../api/zekay/Zekay.typed";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useZekay from "../../../contexts/controllers/useZekay";
import SessionsList from "../sessions/SessionsList";
import useAuth from "../../../contexts/controllers/useAuth";
import { SAnalytics, SCreate, SDone } from "../../../styles/StyledMUI";
import TrainingSummaryModal from "./TrainingSummaryModal";

type TrainingItemProps = {
  training: Training;
  customer: ZekayCustomer | undefined;
  setCustomerUpdated: Dispatch<SetStateAction<ZekayCustomer | undefined>>;
  setTrainingsFormOpen: Dispatch<SetStateAction<boolean>>;
};

export default function TrainingItem(props: TrainingItemProps) {
  const { getCustomer, createSession } = useZekay();
  const { user } = useAuth();
  const [openCourseList, setOpenCourseList] = React.useState<boolean>(false);
  const [summaryOpen, setSummaryOpen] = React.useState<boolean>(false);

  const styles = {
    Typography: {
      color: "white",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
  };

  const handleCreateSession = () => {
    if (props.customer && props.training) {
      const s: Session = {
        CustomerId: props.customer.Id,
        CreditId: props.training.CreditId,
        Date: new Date().toISOString().split("T")[0],
        Instructor: user?.Name,
        Form: undefined,
      };

      createSession(s).then(() => {
        getCustomer(String(props.customer?.Id))
          .then((c) => props.setCustomerUpdated(c))
          .then(() => setOpenCourseList(true));
      });
    }
  };

  const handleSessionSaved = () => {
    if (props.customer) {
      getCustomer(String(props.customer.Id)).then((c) =>
        props.setCustomerUpdated(c)
      );
    }
  };

  const handleSummaryOpen = () => {
    getCustomer(String(props.customer?.Id)).then((c) => {
      const updatedTraining = c?.Trainings?.find(
        (t) => t.ID === props.training.ID
      );
      if (updatedTraining) {
        props.setCustomerUpdated((prevCustomer) =>
          prevCustomer
            ? {
                ...prevCustomer,
                Trainings: prevCustomer.Trainings?.map((t) =>
                  t.ID === updatedTraining.ID ? updatedTraining : t
                ),
              }
            : undefined
        );
      }
      setSummaryOpen(true);
    });
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            backgroundColor: "#171717",
            height: "60px",
            borderRadius: "10px",
            padding: "0 1rem",
          }}
        >
          <IconButton
            variant="plain"
            color="neutral"
            onClick={() => setOpenCourseList(!openCourseList)}
          >
            {openCourseList ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowUpIcon />
            )}
          </IconButton>

          <Box sx={{ ml: 2, flex: 1 }}>
            <Typography sx={styles.Typography}>
              {props.training.Agency}
            </Typography>
          </Box>

          <Box sx={{ ml: 2, flex: 1 }}>
            <Typography sx={styles.Typography}>
              {props.training.Qualification}
            </Typography>
          </Box>

          {props.training.Sessions !== undefined && (
            <Tooltip title="Voir synthèse">
              <IconButton
                variant="plain"
                color="neutral"
                onClick={handleSummaryOpen}
              >
                <SAnalytics sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          )}

          <Box sx={{ ml: 2 }}>
            {props.training.Status === "En cours" ? (
              <IconButton
                variant="plain"
                color="primary"
                onClick={handleCreateSession}
              >
                <SCreate sx={{ color: "white" }} />
              </IconButton>
            ) : (
              <IconButton variant="plain" color="success">
                <SDone sx={{ color: "green" }} />
              </IconButton>
            )}
          </Box>
        </Box>

        {openCourseList && (
          <Box sx={{ mt: 2 }}>
            <SessionsList
              training={props.training}
              onSessionSave={handleSessionSaved}
            />
          </Box>
        )}
      </Box>

      <TrainingSummaryModal
        open={summaryOpen}
        onClose={() => setSummaryOpen(false)}
        customerId={props.customer?.Id}
        creditId={props.training.CreditId}
      />
    </>
  );
}
