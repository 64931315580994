import { Dispatch, SetStateAction } from "react";
import { Box, IconButton } from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface PdfComponentProps {
  file: string | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function PdfComponent({ file, setIsOpen }: PdfComponentProps) {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1300, // Assure que le composant est au-dessus
        backgroundColor: "white",
        display: "flex",
        padding: 4, // Ajoute de l'espace autour de l'iframe
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <IconButton onClick={() => setIsOpen(false)}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <iframe
          src={file}
          title="PDF Viewer"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      </Box>
    </Box>
  );
}
