import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Typography,
  Input,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/joy";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { PortalCustomer, PortalResponse } from "../../../api/auth/Auth.typed";
import useAuth from "../../../contexts/controllers/useAuth";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { useAlert } from "../../../hooks/useAlert";

interface PortalLoginModalProps {
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  setCustomer: Dispatch<SetStateAction<PortalResponse | undefined>>;
}

export default function PortalLoginModal(props: PortalLoginModalProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { loginPortal, getPortalCustomerInfo } = useAuth();
  const { showAlert } = useAlert();
  const location = useLocation();

  const handleSubmit = async () => {
    if (password && email) {
      setIsLoading(true);
      const creditId = new URLSearchParams(location.search).get("creditId");

      const PortalCustomer: PortalCustomer = {
        Email: email,
        Password: password,
      };

      try {
        if (creditId) {
          await loginPortal(PortalCustomer, creditId).then(async (c) => {
            if (c) {
              Cookies.set("authToken", c.Token, { expires: 1 });
              await getPortalCustomerInfo(c.Token).then((cus) => {
                props.setCustomer(cus);
                showAlert("Connexion réussie !", "success");
                props.setIsLoggedIn(true);
              });
            }
          });
        } else {
          showAlert("Aucun creditId renseigné", 'danger');
        }
      } catch (err) {
        if (err instanceof Error) showAlert(err.message, 'danger');
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      const decodedToken: JwtPayload = jwtDecode(authToken);
      if (decodedToken.exp) {
        const expDate = new Date(decodedToken.exp * 1000);
        if (expDate < new Date()) {
          showAlert("Session expirée !", 'warning');
          props.setIsLoggedIn(false);
        } else {
          getPortalCustomerInfo(authToken).then((c) => {
            props.setCustomer(c);
            props.setIsLoggedIn(true);
          });
        }
      }
    }
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "background.paper",
        padding: 3,
        borderRadius: "md",
        boxShadow: "lg",
        maxWidth: 400,
        width: "90%",
        textAlign: "center",
      }}
    >
      <Typography level="h4" sx={{ marginBottom: 2 }}>
        Bienvenue !
      </Typography>
      <Input
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Input
        placeholder="Mot de passe"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        endDecorator={
          <IconButton
            variant="plain"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        }
        sx={{ marginBottom: 3 }}
      />
      <Box>
        {isLoading ? (
          <CircularProgress size="lg" />
        ) : (
          <Button
            onClick={handleSubmit}
            variant="solid"
            disabled={!email || !password}
          >
            Se connecter
          </Button>
        )}
      </Box>
    </Box>
  );
}
