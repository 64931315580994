import React, { ReactNode } from "react";
import { Alert, IconButton, Box } from "@mui/joy";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ColorPaletteProp } from "@mui/joy/styles";

interface AlertProviderProps {
  children: ReactNode;
}

interface AlertContextType {
  showAlert: (newMessage: string, newSeverity: ColorPaletteProp) => void;
}

export const AlertContext = React.createContext<AlertContextType>(null!);

export function AlertProvider({ children }: AlertProviderProps) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<ColorPaletteProp>("neutral");

  const showAlert = (newMessage: string, newSeverity: ColorPaletteProp) => {
    setMessage(newMessage);
    setSeverity(newSeverity);
    setOpen(true);

    // Automatically hide the alert after 3 seconds
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const hideAlert = () => {
    setOpen(false);
  };

  const icons = {
    primary: <></>,
    success: <CheckCircleIcon />,
    warning: <WarningIcon />,
    danger: <ReportIcon />,
    neutral: <InfoIcon />,
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {open && (
        <Box
          sx={{
            position: "fixed",
            top: 20,
            right: 20,
            zIndex: 1400,
            width: 300,
            opacity: open ? 1 : 0,
            transform: open ? "translateY(0)" : "translateY(-20px)",
            transition: "opacity 0.3s ease, transform 0.3s ease",
          }}
        >
          <Alert
            color={severity}
            variant="solid"
            startDecorator={icons[severity]}
            endDecorator={
              <IconButton
                variant="plain"
                size="sm"
                color="neutral"
                onClick={hideAlert}
              >
                <CloseRoundedIcon />
              </IconButton>
            }
            sx={{
              borderRadius: "md",
              boxShadow: "lg",
              padding: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {message}
          </Alert>
        </Box>
      )}
    </AlertContext.Provider>
  );
}
