import React from "react";
import { Box, Typography, IconButton, Avatar, Link } from "@mui/joy";
import AssignmentIcon from "@mui/icons-material/Assignment";

interface TrainingTestProps {
  testUrl: string | undefined;
}

export default function TrainingTest({ testUrl }: TrainingTestProps) {
  const renderIconButton = (url: string, icon: React.ReactElement) => (
    <Box textAlign="center">
      <Link
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <IconButton
          sx={{
            bgcolor: "primary.solidBg",
            width: 56,
            height: 56,
            margin: 1,
            "&:hover": {
              bgcolor: "primary.solidHoverBg",
            },
          }}
        >
          <Avatar
            sx={{
              bgcolor: "transparent",
              width: 24,
              height: 24,
            }}
          >
            {icon}
          </Avatar>
        </IconButton>
      </Link>
    </Box>
  );

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#89cff0",
        borderRadius: "8px",
        color: "text.primary",
        fontFamily: "Roboto, sans-serif",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {testUrl ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {renderIconButton(
              testUrl,
              <AssignmentIcon sx={{ color: "white" }} />
            )}
            <Typography fontSize="xs" mt={1}>
              mot de passe : <i>escapadeqcm</i>
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
