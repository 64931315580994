import React from "react";
import { Box, IconButton, Typography } from "@mui/joy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ZekayCustomer } from "../../../../api/zekay/Zekay.typed";
import TrainingList from "../../trainings/TrainingList";
import ProfileForm from "../../profiles/ProfileForm";
import useZekay from "../../../../contexts/controllers/useZekay";
import { SView } from "../../../../styles/StyledMUI";

type CustomersItemProps = {
  customer: ZekayCustomer | undefined;
};

export default function CustomerItem(props: CustomersItemProps) {
  const { getCustomer } = useZekay();
  const [customerUpdated, setCustomerUpdated] = React.useState<ZekayCustomer>();
  const [trainingsOpen, setTrainingsOpen] = React.useState(false);
  const [profileFormOpen, setProfileFormOpen] = React.useState(false);

  const handleClickOpenProfile = () => {
    getCustomer(String(props.customer?.Id)).then((r) => setCustomerUpdated(r));
    setProfileFormOpen(true);
  };

  const styles = {
    Typography: {
      color: "white",
      fontSize: "clamp(0.8rem, 2vw, 1rem)",
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          backgroundColor: "#171717",
          height: "60px",
          borderRadius: "10px",
          padding: "0 1rem",
          flex: 1,
        }}
      >
        <IconButton
          aria-label="expand row"
          onClick={() => {
            getCustomer(String(props.customer?.Id)).then((c) =>
              setCustomerUpdated(c)
            );
            setTrainingsOpen(!trainingsOpen);
          }}
        >
          {trainingsOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>

        <Box sx={{ width: "20%", ml: 1 }}>
          <Typography sx={styles.Typography}>
            {customerUpdated?.LastQualification ||
              props.customer?.LastQualification}
          </Typography>
        </Box>

        <Box sx={{ width: "70%" }}>
          <Typography sx={styles.Typography}>
            {props.customer?.FirstName + " " + props.customer?.LastName}
          </Typography>
        </Box>

        <IconButton onClick={handleClickOpenProfile}>
          <SView />
        </IconButton>
      </Box>

      {trainingsOpen && (
        <Box sx={{ mt: 2 }}>
          <TrainingList
            customer={customerUpdated || props.customer}
            setCustomerUpdated={setCustomerUpdated}
            setTrainingsOpen={setTrainingsOpen}
          />
        </Box>
      )}

      {profileFormOpen && (
        <ProfileForm
          customer={customerUpdated || props.customer}
          setProfileFormOpen={setProfileFormOpen}
          setCustomerUpdated={setCustomerUpdated}
        />
      )}
    </Box>
  );
}
